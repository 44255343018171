import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { CryptoService } from "./crypto.service";
import { LoginUserResponse } from "src/app/models/users/login-user-response";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: "root",
})
export class ContextService {
    USER_ID = "id";
    USER_INFO = "userInfo";

    constructor(
        private cryptoService: CryptoService,
        private messageService: MessageService,
        private router: Router
    ) {}

    setLoginResponse(response: LoginUserResponse) {
        const userId = response.id;
        localStorage.setItem(this.USER_ID, userId);
        localStorage.setItem(
            `${this.USER_INFO}_${userId}`,
            this.cryptoService.encryptMessage(response)
        );
    }

    isLoggedIn(): boolean {
        let userInfo = this.getUserInfo();
        return userInfo != null && userInfo?.token != null;
    }

    getUserInfo(): LoginUserResponse {
        const userId = localStorage.getItem(this.USER_ID);
        const encryptedUserInfo = localStorage.getItem(
            `${this.USER_INFO}_${userId}`
        );
        if (encryptedUserInfo != null && encryptedUserInfo != undefined) {
            let userInfo: LoginUserResponse =
                this.cryptoService.decryptMessage(encryptedUserInfo);
            return userInfo;
        } else {
            return null;
        }
    }

    logout(): void {
        localStorage.removeItem(`token`);
        localStorage.removeItem(`username`);
        localStorage.removeItem(`fotoPerfil`);
        localStorage.removeItem(`perfil`);
        localStorage.removeItem(`id`);
        localStorage.removeItem(`fullname`);
        this.messageService.add({
            severity: `info`,
            summary: `Log-Out!`,
            detail: `Seu acesso foi encerrado`,
        });
        this.router.navigate([`/login`]);
    }
}
