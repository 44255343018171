import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import type { Card } from '../interfaces/card';

@Injectable({
  providedIn: 'root',
})
export class CrmService {
  private cardsSubject = new BehaviorSubject<Card[]>([]);
  cards$ = this.cardsSubject.asObservable();

  constructor() {
    this.loadInitialData();
  }

  private loadInitialData() {
    const savedData = sessionStorage.getItem('cardsData');
    if (savedData) {
      this.cardsSubject.next(JSON.parse(savedData) as Card[]);
    } else {
      const initialData: Card[] = [
        {
          id: 1,
          client: 'Eraldo Carlos Oliveira',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Não Responde',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Orçamento',
              description: 'Cliente movido para etapa de Orçamento',
            },
            {
              id: 4,
              date: '12 de Novembro de 2024',
              title: 'Não Responde',
              description: 'Cliente movido para etapa de Não Responde',
            },
          ],
        },
        {
          id: 2,
          client: 'Luana Ramos de Lima',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Itaporanga-PB',
          status: 'Cadastrado',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Orçamento',
              description: 'Cliente movido para etapa de Orçamento',
            },
            {
              id: 4,
              date: '15 de Novembro de 2024',
              title: 'Cadastrado',
              description: 'Cliente cadastrado no sistema em 15/11/2024 ás 10h35',
            },
          ],
        },
        {
          id: 3,
          client: 'Maycon Douglas',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '12/10/2024',
          city: 'Patos-PB',
          status: 'Perdido',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Orçamento',
              description: 'Cliente movido para etapa de Orçamento',
            },
            {
              id: 4,
              date: '16 de Novembro de 2024',
              title: 'Perdido',
              description: 'Cliente movido para etapa de Perdido',
            },
          ],
        },
        {
          id: 4,
          client: 'Jefferson Salvino',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '12/10/2024',
          city: 'Cajazeiras-PB',
          status: 'Prospectado',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
          ],
        },
        {
            id: 5,
            client: 'Maria Goreth Silva',
            phone: '(47) 98486-2890',
            seller: 'Jeferson Franklin',
            date: '10/10/2024',
            city: 'Piancó-PB',
            status: 'Prospectado',
            historical: [
                {
                    id: 1,
                    date: '03 de Novembro de 2024',
                    title: 'Prospectado',
                    description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
                },
            ],
        },
        {
            id: 6,
            client: 'Luís Davi Oliveira',
            phone: '(47) 98486-2890',
            seller: 'Jeferson Franklin',
            date: '10/10/2024',
            city: 'João Pessoa-PB',
            status: 'Não Responde',
            historical: [
                {
                    id: 1,
                    date: '03 de Novembro de 2024',
                    title: 'Prospectado',
                    description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
                },
                {
                    id: 2,
                    date: '05 de Novembro de 2024',
                    title: 'Em Contato',
                    description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
                },
                {
                    id: 3,
                    date: '07 de Novembro de 2024',
                    title: 'Agendamento de Contato',
                    description: 'Efetuado agendamento de contato com o cliente para o dia 12/11/2024 ás 15h00',
                },
                {
                    id: 4,
                    date: '12de Novembro de 2024',
                    title: 'Não Responde',
                    description: 'Cliente movido para etapa de Não Responde',
                },
            ],
        },
        {
          id: 7,
          client: 'Leandro Medeiros',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Fechado',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Orçamento',
              description: 'Cliente movido para etapa de Orçamento',
            },
            {
              id: 4,
              date: '12 de Novembro de 2024',
              title: 'Fechado',
              description: 'Cliente movido para etapa de Fechado',
            },
          ],
        },
        {
          id: 8,
          client: 'Fernanda Santos',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '10/10/2024',
          city: 'Patos-PB',
          status: 'Reprovado',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Cadastrado',
              description: 'Cliente cadastrado no sistema em 07/11/2024 ás 08h53',
            },
            {
              id: 4,
              date: '11 de Novembro de 2024',
              title: 'Reprovado',
              description: 'Cliente movido para etapa de Reprovado',
            },
          ],
        },
        {
          id: 9,
          client: 'Francisco Oliveira',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'João Pessoa-PB',
          status: 'Contrato',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '09 de Novembro de 2024',
              title: 'Cadastrado',
              description: 'Cliente cadastrado no sistema em 09/11/2024 ás 15h28',
            },
            {
              id: 4,
              date: '15 de Novembro de 2024',
              title: 'Contrato',
              description: 'Cliente movido para etapa de Contrato',
            },
          ],
        },
        {
          id: 10,
          client: 'Amanda Nunes Ferreira',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '12/10/2024',
          city: 'Campina Grande-PB',
          status: 'Em Contato',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 12/11/2024 ás 09h00',
            },
            {
              id: 4,
              date: '12 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 19/11/2024 ás 15h00',
            },
          ],
        },
        {
          id: 11,
          client: 'Eraldo Carlos Oliveira 11',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Orçamento',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Orçamento',
              description: 'Cliente movido para etapa de Orçamento',
            }
          ],
        },
        {
          id: 12,
          client: 'Eraldo Carlos Oliveira 12',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Em Contato',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 11/11/2024 ás 13h40',
            },
            {
              id: 4,
              date: '11 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 17/11/2024 ás 13h00',
            },
          ],
        },
        {
          id: 13,
          client: 'Eraldo Carlos Oliveira 13',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Em Contato',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 12/11/2024 ás 15h00',
            },
            {
              id: 4,
              date: '12 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 16/11/2024 ás 14h00',
            },
          ],
        },
        {
          id: 14,
          client: 'Eraldo Carlos Oliveira 14',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Perdido',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 12/11/2024 ás 15h00',
            },
            {
              id: 4,
              date: '10 de Novembro de 2024',
              title: 'Perdido',
              description: 'Cliente movido para etapa de Perdido',
            },
          ],
        },
        {
          id: 15,
          client: 'Eraldo Carlos Oliveira 15',
          phone: '(47) 98486-2890',
          seller: 'Jeferson Franklin',
          date: '11/10/2024',
          city: 'Patos-PB',
          status: 'Em Contato',
          historical: [
            {
              id: 1,
              date: '03 de Novembro de 2024',
              title: 'Prospectado',
              description: 'Cliente prospectado através de campanha de Natal 2024 Nordsol',
            },
            {
              id: 2,
              date: '05 de Novembro de 2024',
              title: 'Em Contato',
              description: 'Cliente movido para etapa de Em Contato pelo Vendedor: Vendas Nordsol',
            },
            {
              id: 3,
              date: '07 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 13/11/2024 ás 11h00',
            },
            {
              id: 4,
              date: '13 de Novembro de 2024',
              title: 'Agendamento de Contato',
              description: 'Efetuado agendamento de contato com o cliente para o dia 17/11/2024 ás 09h00',
            },
          ],
        },
      ];
      this.cardsSubject.next(initialData);
      sessionStorage.setItem('cardsData', JSON.stringify(initialData));
    }
  }

  private saveToSessionStorage(newCard: Card) {
    const savedData = sessionStorage.getItem('cardsData');
    const currentCards = savedData ? JSON.parse(savedData) : [];
    currentCards.push(newCard);
    sessionStorage.setItem('cardsData', JSON.stringify(currentCards));
    this.cardsSubject.next(currentCards);
  }

  updateCardStatus(cardId: number, newStatus: string) {
    const currentCards = this.cardsSubject.value;
    const updatedCards = currentCards.map((card) =>
      card.id === cardId ? { ...card, status: newStatus } : card
    );
    this.cardsSubject.next(updatedCards);
    sessionStorage.setItem('cardsData', JSON.stringify(updatedCards));
  }

  addCard(card: Card) {
    this.saveToSessionStorage(card);
  }

  updateCardInfo(cardId: number, updatedInfo: Partial<Card>) {
    const currentCards = this.cardsSubject.value;
    const updatedCards = currentCards.map((card) =>
      card.id === cardId ? { ...card, ...updatedInfo } : card
    );
    this.cardsSubject.next(updatedCards);
    this.saveToSessionStoragItens(updatedCards);
  }

  private saveToSessionStoragItens(data: Card[]) {
    sessionStorage.setItem('cardsData', JSON.stringify(data));
  }
}
