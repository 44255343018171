export enum StepEnum {
    SemProjeto,
    EmAndamento,
    Aconcluir,
    Concluido,
    Enviado,
}

export const StepEnumLabel = new Map<number, string>([
    [StepEnum.SemProjeto, "Sem Projeto"],
    [StepEnum.EmAndamento, "Em Andamento"],
    [StepEnum.Aconcluir, "A Concluir"],
    [StepEnum.Concluido, "Concluído"],
    [StepEnum.Enviado, "Enviado"],
]);
