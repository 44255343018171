import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GetAddressService {

  constructor(private http: HttpClient) { }

  getAddressByZipCode(zipCode: string){

    zipCode = zipCode.replace(/\D/g, '');

    if (zipCode != null) {
      const validZipCode = /^[0-9]{8}$/;

      if (validZipCode.test(zipCode)) {
        return this.http.get(`https://viacep.com.br/ws/${zipCode}/json`).pipe(take(1));
      }
    }
    return of({});
  }
}
