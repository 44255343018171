import { Injectable } from "@angular/core";
import { ImageProfileEnum } from "../providers/Enums/perfil-Imagem-Enum";
import { Cliente } from "../providers/models/cliente";
import { Galeria } from "../providers/models/galeria";
import { HttpClientService } from "../providers/services/http-client.service";
import { GaleriaDeleteRequest } from "../providers/models/galeria-request";

@Injectable({
    providedIn: "root",
})
export class GaleryService {
    constructor(private httpClient: HttpClientService) {}

    public getTabelas(): Promise<Galeria[]> {
        return this.httpClient.get("/Galeria", "");
    }

    public getTabelaPorId(tabelaId: number): Promise<Galeria> {
        return this.httpClient.get("/Galeria", `/${tabelaId}`);
    }

    public getGaleriasPorUsuarioId(usuarioId: number): Promise<Galeria[]> {
        return this.httpClient.get("/Galeria", `/usuario-id/${usuarioId}`);
    }

    public getGaleriasPorPerfil(
        perfilGaleria: ImageProfileEnum,
        usuarioId: number
    ): Promise<Galeria[]> {
        return this.httpClient.get(
            "/Galeria",
            `/perfil/galeria/${perfilGaleria}/${usuarioId}`
        );
    }

    public getTabelaPorDescricao(descricao: string): Promise<Galeria> {
        return this.httpClient.get("/Galeria", `/descricao/${descricao}`);
    }

    public salvarTabela(
        usuarioId: number,
        perfil: number,
        file: File
    ): Promise<Galeria> {
        const fileToUpload = file as File;
        const formData = new FormData();
        formData.append("file", fileToUpload);

        return this.httpClient.post(
            "/Galeria",
            `/upload-tabela/${usuarioId}/${perfil}`,
            formData
        );
    }

    public deleteRegistroOs(
        osId: number,
        url: string,
        usuarioLogadoId: number
    ): Promise<any> {
        const formData = new FormData();
        formData.append(url, url);
        return this.httpClient.post(
            "/Galeria",
            `/os-cliente/${osId}/${usuarioLogadoId}`,
            formData
        );
    }

    public deleteRegistroRastreio(
        rastreioId: number,
        url: string,
        usuarioLogadoId: number
    ): Promise<any> {
        const formData = new FormData();
        formData.append(url, url);
        return this.httpClient.post(
            "/Galeria",
            `/rastreio-cliente/${rastreioId}/${usuarioLogadoId}`,
            formData
        );
    }

    public deleteRegistroFerramenta(
        ferramentaId: number,
        url: string,
        usuarioLogadoId: number
    ): Promise<any> {
        const formData = new FormData();
        formData.append(url, url);
        return this.httpClient.post(
            "/Galeria",
            `/ferramenta-usuario/${ferramentaId}/${usuarioLogadoId}`,
            formData
        );
    }

    public deleteDocumentoCliente(
        usuarioLogadoId: number,
        nomeCliente: string,
        enderecoId: number,
        url: string
    ): Promise<any> {
        const formData = new FormData();
        formData.append(url, url);
        return this.httpClient.post(
            "/Galeria",
            `/deletar-documento-cliente/${usuarioLogadoId}/${nomeCliente}/${enderecoId}`,
            formData
        );
    }

    public deleteImagemManutencao(
        url: string,
        usuarioLogadoId: number
    ): Promise<any> {
        return this.httpClient.post(
            "/Galeria",
            `/imagem/manutencao/${usuarioLogadoId}`,
            url
        );
    }

    public deletePdfTable(
        galeriaDeleteRequest: GaleriaDeleteRequest
    ): Promise<any> {
        return this.httpClient.post(
            "/Galeria",
            `/remover-tabela-preco`,
            galeriaDeleteRequest
        );
    }
}
