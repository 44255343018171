import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { MessageService } from "primeng/api";
import { UsuarioUpdateSenha } from "../providers/models/usuario-update-senha";
import { UsuarioLogin } from "../providers/models/usuarioLogin";
import { UsuarioLoginRequest } from "../providers/models/usuarioLoginRequest";
import { HttpClientService } from "../providers/services/http-client.service";
import { LoginUserResponse } from "../models/users/login-user-response";
import { CryptoService } from "../providers/services/crypto.service";
import { ProfileEnum } from "../providers/Enums/profile-Enum";
import { ContextService } from "../providers/services/context.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    public endpoint = `api/UsuarioLogin`;
    public jwtHelper = new JwtHelperService();
    public decodedToken: any;
    USER_ID = "id";
    USER_INFO = "userInfo";

    constructor(
        private httpClient: HttpClientService,
        private router: Router,      
        private contextService: ContextService,
        private messageService: MessageService
    ) {}   

    login(model: UsuarioLoginRequest) {
        return this.httpClient
            .post(`/UsuarioLogin`, `/login`, model)
            .then((resp) => {
                if (!resp.status) {
                    return this.messageService.add({
                        severity: "error",
                        summary: "Erro ao logar!",
                        detail: resp.message,
                    });
                } else {
                    localStorage.setItem(`token`, resp.token);
                    this.decodedToken = this.jwtHelper.decodeToken(resp.token);
                    localStorage.setItem(`username`, resp.userName);
                    localStorage.setItem(`fullname`, resp.fullName);
                    localStorage.setItem(`perfil`, resp.perfil);
                    localStorage.setItem(`fotoPerfil`, resp.fotoPerfil);
                    localStorage.setItem(`id`, resp.id);
                    this.contextService.setLoginResponse(resp);
                    return resp;
                }
            });
    }

    cadastrarUserLogin(model: UsuarioLogin): Promise<UsuarioLogin> {
        return this.httpClient.post(`/UsuarioLogin`, `/cadastrar`, model);
    }

    updateUserLogin(
        model: UsuarioLogin,
        isProfile: boolean
    ): Promise<UsuarioLogin> {
        return this.httpClient.put(
            `/UsuarioLogin`,
            `/atualizar/${isProfile}`,
            model
        );
    }

    deleteUserLogin(model: any): Promise<UsuarioLogin> {
        return this.httpClient.put(`/UsuarioLogin`, `/deletar/usuario`, model);
    }

    updateSenha(model: UsuarioUpdateSenha): Promise<UsuarioLogin> {
        return this.httpClient.put(`/UsuarioLogin`, `/atualizar/senha`, model);
    }

    getToken() {
        return localStorage.getItem(`token`);
    }

    userName(): string {
        return localStorage.getItem(`username`)!;
    }

    userFullName(): string {
        return localStorage.getItem(`fullname`)!;
    }

    userId(): number {
        return +localStorage.getItem(`id`)!;
    }

    setImagem(fotoPerfil: string) {
        localStorage.removeItem(`fotoPerfil`);
        return localStorage.setItem(`fotoPerfil`, fotoPerfil);
    }

    userImage(): string {
        return localStorage.getItem(`fotoPerfil`)!;
    }

    perfil(): number {
        return +localStorage.getItem(`perfil`)!;
    }

    loggedIn() {
        const token = localStorage.getItem(`token`)!;
        return !this.jwtHelper.isTokenExpired(token!);
    }

    logout(): void {
        localStorage.removeItem(`token`);
        localStorage.removeItem(`username`);
        localStorage.removeItem(`fotoPerfil`);
        localStorage.removeItem(`perfil`);
        localStorage.removeItem(`id`);
        localStorage.removeItem(`fullname`);
        this.messageService.add({
            severity: `info`,
            summary: `Log-Out!`,
            detail: `Seu acesso foi encerrado`,
        });
        this.router.navigate([`/autenticacao`]);
    }
    
    isLoggedIn(): boolean {
        let userInfo = this.contextService.getUserInfo();
        return userInfo != null && userInfo?.token != null;
    }
    
    getUserPerfil(): ProfileEnum {
        return this.contextService.getUserInfo().perfil;
    }

    getUserId(): string {
        return localStorage.getItem(this.USER_ID);
    }

    getUsername(): string {
        const userInfo = this.contextService.getUserInfo();
        return userInfo.userName;
    }

    setImage(fotoPerfil: string) {
        localStorage.removeItem("fotoPerfil");
        return localStorage.setItem("fotoPerfil", fotoPerfil);
    }

    getFullNameUser(): string {
        const userInfo = this.contextService.getUserInfo();
        return userInfo.fullName;
    }
}
