<div class="title-page flex align-items-center">
    <span class="ml-2">{{projects.length ? 'Projetos Cadastados' : 'Cadastrar Projeto'}}</span>
</div>
<div class="container p-4" *ngIf="!projects.length">
    <form class="grid p-2 mb-6" [formGroup]="formProject">
        <div class="mt-4 p-fluid p-float-label col-12 md:col-6">
            <input class="textUppercase" pInputText [(ngModel)]="project.nome" formControlName="nome"
                [ngClass]="cssValidator(getProjectForm['nome'])">
            <label class="ml-1">* Nome</label>
            <div *ngIf="getProjectForm['nome'].errors?.['required'] && getProjectForm['nome'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
            <div *ngIf="getProjectForm['nome'].errors?.['minlength']" class="p-error mt-1">
                Campo deve ter no mínimo 3 caracteres!
            </div>
            <div *ngIf="getProjectForm['nome'].errors?.['maxlength']" class="p-error mt-1">
                Campo deve ter no máximo 60 caracteres!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-3">
            <input class="textUppercase" pInputText [(ngModel)]="project.rg" formControlName="rg"
                [ngClass]="cssValidator(getProjectForm['rg'])">
            <label class="ml-1">* RG</label>
            <div *ngIf="getProjectForm['rg'].errors?.['required'] && getProjectForm['rg'].touched" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-3">
            <p-inputMask [(ngModel)]="project.cpf" formControlName="cpf" (onBlur)="cpfValido(project.cpf)"
                [ngClass]="cssValidator(getProjectForm['cpf'])" [unmask]="true" mask="999.999.999-99">
            </p-inputMask>
            <label class="ml-1">* CPF</label>
            <div *ngIf="getProjectForm['cpf'].errors?.['required'] && getProjectForm['cpf'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber formControlName="potenciaSistema" mode="decimal" [minFractionDigits]="2"
                [maxFractionDigits]="3" [(ngModel)]="project.potenciaSistema" [min]="0" suffix=" KWP"
                [ngClass]="cssValidator(getProjectForm['potenciaSistema'])">
            </p-inputNumber>
            <label class="ml-1">* Potência do Sistema (KWP)</label>
            <div *ngIf="getProjectForm['potenciaSistema'].errors?.['required'] && getProjectForm['potenciaSistema'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber formControlName="potenciaInversor" [minFractionDigits]="2" [maxFractionDigits]="3"
                [(ngModel)]="project.potenciaInversor" [min]="0" suffix=" KW"
                [ngClass]="cssValidator(getProjectForm['potenciaInversor'])">
            </p-inputNumber>
            <label class="ml-1">* Potência do Inversor (KW)</label>
            <div *ngIf="getProjectForm['potenciaInversor'].errors?.['required'] && getProjectForm['potenciaInversor'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <input class="textUppercase" pInputText [(ngModel)]="project.marcaInversor" formControlName="marcaInversor"
                [ngClass]="cssValidator(getProjectForm['marcaInversor'])">
            <label class="ml-1">* Marca do Inversor</label>
            <div *ngIf="getProjectForm['marcaInversor'].errors?.['required'] && getProjectForm['marcaInversor'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber [(ngModel)]="project.quantidadeDePaineis" formControlName="quantidadeDePaineis"
                (onBlur)="setSystemPower()" mode="decimal" [min]="0" [max]="100" suffix=" Unidades"
                [ngClass]="cssValidator(getProjectForm['quantidadeDePaineis'])">
            </p-inputNumber>
            <label class="ml-1">* Quantidade de Painéis</label>
            <div *ngIf="getProjectForm['quantidadeDePaineis'].errors?.['required'] && getProjectForm['quantidadeDePaineis'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <input class="textUppercase" pInputText [(ngModel)]="project.marcaPaineis" formControlName="marcaPaineis"
                [ngClass]="cssValidator(getProjectForm['marcaPaineis'])">
            <label class="ml-1">* Marca dos Painéis</label>
            <div *ngIf="getProjectForm['marcaPaineis'].errors?.['required'] && getProjectForm['marcaPaineis'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber formControlName="potenciaPaineis" [(ngModel)]="project.potenciaPaineis" [min]="0" suffix=" W"
                [ngClass]="cssValidator(getProjectForm['potenciaPaineis'])" (onBlur)="setSystemPower()">
            </p-inputNumber>
            <label class="ml-1">* Potência dos Painéis (W)</label>
            <div *ngIf="getProjectForm['potenciaPaineis'].errors?.['required'] && getProjectForm['potenciaPaineis'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid col-12">
            <label class="ml-1">Observação</label>
            <textarea pInputTextarea [(ngModel)]="project.observacao" formControlName="observacao" rows="15"
                style="resize: none; height: 100%;" [ngClass]="cssValidator(getProjectForm['observacao'])">
      </textarea>
        </div>
        <div class="mt-5 p-fluid col-12 ml-auto md:col-2">
            <p-button label="Cadastrar" size="small" (click)="onSubmitProject()">
            </p-button>
        </div>
    </form>
</div>

<div class="container p-4" *ngIf="projects.length">
    <div class="grid" *ngFor="let project of projects">
        <div class="mt-4 p-fluid p-float-label col-12 md:col-6">
            <input class="textUppercase" pInputText [(ngModel)]="project.nome"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            <label class="ml-1">* Nome</label>
            <div *ngIf="project.nome == ''" class="p-error mt-1">
                Campo obrigatório!
            </div>
            <div *ngIf="project.nome.length < 3" class="p-error mt-1">
                Campo deve ter no mínimo 3 caracteres!
            </div>
            <div *ngIf="project.nome.length > 60" class="p-error mt-1">
                Campo deve ter no máximo 60 caracteres!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-3">
            <input class="textUppercase" pInputText [(ngModel)]="project.rg"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            <label class="ml-1">* RG</label>
            <div *ngIf="project.rg == ''" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-3">
            <p-inputMask [(ngModel)]="project.cpf" (onBlur)="cpfValido(project.cpf)" [unmask]="true"
                mask="999.999.999-99" [readonly]="project.etapa == 3 || !isViewEdit()">
            </p-inputMask>
            <label class="ml-1">* CPF</label>
            <div *ngIf="project.cpf == ''" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="3"
                [(ngModel)]="project.potenciaSistema" [min]="0" suffix=" KWP"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            </p-inputNumber>
            <label class="ml-1">* Potência do Sistema (KWP)</label>
            <div *ngIf="project.potenciaSistema == undefined" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber mode="decimal" [min]="2" [maxFractionDigits]="3" suffix=" KW"
                [(ngModel)]="project.potenciaInversor" [readonly]="project.etapa == 3 || !isViewEdit()">
            </p-inputNumber>
            <label class="ml-1">* Potência do Inversor (KW)</label>
            <div *ngIf="project.potenciaInversor == undefined" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <input class="textUppercase" pInputText [(ngModel)]="project.marcaInversor"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            <label class="ml-1">* Marca do Inversor</label>
            <div *ngIf="project.marcaInversor == ''" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber [(ngModel)]="project.quantidadeDePaineis" mode="decimal" [min]="0" [max]="100"
                suffix=" Unidades" [readonly]="project.etapa == 3 || !isViewEdit()">
            </p-inputNumber>
            <label class="ml-1">* Quantidade de Painéis</label>
            <div *ngIf="project.quantidadeDePaineis == undefined" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <input class="textUppercase" pInputText [(ngModel)]="project.marcaPaineis"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            <label class="ml-1">* Marca dos Painéis</label>
            <div *ngIf="project.marcaPaineis == ''" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid p-float-label col-12 md:col-4">
            <p-inputNumber mode="decimal" [min]="0" suffix=" W" [(ngModel)]="project.potenciaPaineis"
                [readonly]="project.etapa == 3 || !isViewEdit()">
            </p-inputNumber>
            <label class="ml-1">* Potência dos Painéis (W)</label>
            <div *ngIf="project.potenciaPaineis == undefined" class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>
        <div class="mt-4 p-fluid col-12">
            <label class="ml-1">Observação</label>
            <textarea pInputTextarea [(ngModel)]="project.observacao" rows="15" style="resize: none; height: 100%;"
                [readonly]="project.etapa == 3 || !isViewEdit()">
      </textarea>
        </div>
        <div class="col-12 md:col-6 flex justify-content-start mt-4 mb-4">
            <p-button label="Excluir" icon="pi pi-trash" size="small" *ngIf="isAdm()"
                (click)="openModalDeleteProject(project)">
            </p-button>
        </div>
        <div class="col-12 md:col-6 flex justify-content-end mt-4 mb-4" *ngIf="isViewEdit()">
            <p-button label="Editar" size="small" (click)="onUpdateProject(project)">
            </p-button>
        </div>
    </div>
</div>

<div class="card flex justify-content-center" class="textUppercase">
    <p-dialog header="Header" [(visible)]="deleteProject" [style]="{ width: '50vw' }" [modal]="true" [draggable]="false"
        [resizable]="false">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold"> Deletar Projeto</span>
        </ng-template>
        <p>O projeto será deletado. Deseja continuar?</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" label="Sim" (click)="deleteProjectClient(); deleteProject = false"></p-button>
            <p-button icon="pi pi-times" (click)="deleteProject = false" label="Não"></p-button>
        </ng-template>
    </p-dialog>
</div>