import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { ToastModule } from "primeng/toast";
import { ModalNewInstallmentComponent } from "./modal-new-installment.component";
import { ParcelaService } from "src/app/services/parcela.service";
import { SpinnerService } from "src/app/services/spinner.service";
import { MessageService } from "primeng/api";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        ToastModule,
        ReactiveFormsModule,
        MessageModule,
    ],
    declarations: [ModalNewInstallmentComponent],
    providers: [ParcelaService, SpinnerService, MessageService],
})
export class ModalNewInstallmentModule {}
