import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'src/app/gerenciamento-estado/store/store';
import { Os } from "src/app/providers/models/os";
import { StateOs } from '../state/states';

const initialState: StateOs = { oss: [] }

export class StoreOs extends Store<Os> {
    private subject = new BehaviorSubject<StateOs>(initialState);
    private store$ = this.subject.asObservable();
    
    public override get currentValue() {
        return this.subject.value;
    }

    public override setState(name: string, oss: any): void {     
        this.subject.next({
            ...this.currentValue,
            [name]: oss
        })
    }

    public override getList(): Observable<Os[]> {
        return this.store$.pipe(
            map(store => store.oss)
        )
    }

    public override delete(data: Os, name: string): void {
        const dataUpdateOsCurrent = this.currentValue.oss.map(os=> {
        if (os.id == data.id) {
            return { ...os,  isActive: false};
        }
        return os;
        });

        this.setState(name, dataUpdateOsCurrent)
    }

    public override update(name: string, data: Os): void {
        const dataUpdateOsCurrent = this.currentValue.oss.map(os=> {
        if (os.id == data.id) {
            return { ...data };
        }
        return os;
        });
        
        this.setState(name, dataUpdateOsCurrent);
    }

}
