export enum WithdrawalEnum {
    Motivo0,
    Motivo1,
    Motivo2,
    Motivo3,
    Motivo4,
    Motivo5,
    Motivo6,
}

export const WithdrawalEnumLabel = new Map<number, string>([
    [WithdrawalEnum.Motivo0, "Motivo não informado"],
    [WithdrawalEnum.Motivo1, "Vai fazer mais pra frente"],
    [WithdrawalEnum.Motivo2, "Achou a parcela alta"],
    [WithdrawalEnum.Motivo3, "Achou o valor alto"],
    [WithdrawalEnum.Motivo4, "Vai tentar fazer à vista"],
    [WithdrawalEnum.Motivo5, "Fez com outra empresa"],
    [WithdrawalEnum.Motivo6, "Fez no nome de outra pessoa"],
]);
