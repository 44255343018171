<div class="title-page flex align-items-center">
    <app-button-back></app-button-back>
    <span>{{labelPagina}}{{projectClientId != 0 ? ' - ' + projectClient.id : ''}}</span>
</div>

<div class="container p-5 mb-6">
    <form class="grid p-2 mb-6" [formGroup]="projectClientForm">
        <div class="mt-2 mb-4 p-fluid p-float-label col-12 md:col-4">
            <p-dropdown [options]="projectsOptions" formControlName="tipoProjeto"
                [(ngModel)]="projectClient.tipoProjeto" optionLabel="label" optionValue="value" [filter]="true"
                filterBy="label" [readonly]="index" [ngClass]="cssValidator(getProjectClientForm['tipoProjeto'])"
                (onChange)="setProjectType($event)" filterPlaceholder="Digite para filtrar" [autoDisplayFirst]="false"
                class="textUppercase">
            </p-dropdown>
            <label class="ml-1">* Tipo de Projeto</label>
        </div>
        <div *ngIf="typeProject">
            <div class="grid">
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3" *ngIf="projectClientId == 0">
                    <p-dropdown [options]="clientsOptions" formControlName="clienteNome"
                        [(ngModel)]="projectClient.clienteNome" optionLabel="label" optionValue="value" [filter]="true"
                        filterBy="label" [readonly]="index"
                        [ngClass]="cssValidator(getProjectClientForm['clienteNome'])" (onChange)="setClient($event)"
                        filterPlaceholder="Digite para filtrar" class="textUppercase">
                    </p-dropdown>
                    <label class="ml-1">* Cliente</label>
                    <div *ngIf="getProjectClientForm['clienteNome'].errors?.['required'] && getProjectClientForm['clienteNome'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                    <div *ngIf="getProjectClientForm['clienteNome'].errors?.['minlength']" class="p-error mt-1">
                        Campo deve ter no mínimo 3 caracteres!
                    </div>
                    <div *ngIf="getProjectClientForm['clienteNome'].errors?.['maxlength']" class="p-error mt-1">
                        Campo deve ter no máximo 60 caracteres!
                    </div>
                </div>
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3" *ngIf="projectClientId != 0">
                    <input class="textUppercase" pInputText [(ngModel)]="projectClient.clienteNome"
                        formControlName="clienteNome" readonly>
                    <label class="ml-1">* Cliente</label>
                    <div *ngIf="getProjectClientForm['clienteNome'].errors?.['required'] && getProjectClientForm['clienteNome'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3">
                    <p-dropdown [options]="addressOptions" optionLabel="label" optionValue="value" [filter]="true"
                        filterBy="label" [autoDisplayFirst]="true" (onChange)="setAddressOs($event)"
                        filterPlaceholder="Digite para filtrar" [readonly]="indexAddress" class="textUppercase">
                    </p-dropdown>
                    <label class="ml-1">Endereços</label>
                </div>
                <div class="col-12 md:col-3 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [(ngModel)]="projectClient.concessionaria"
                        formControlName="concessionaria" [readonly]="!projectClient.status"
                        [ngClass]="cssValidator(getProjectClientForm['concessionaria'])">
                    <label style="padding-left: 15px;">* Concessionária</label>
                    <div *ngIf="getProjectClientForm['concessionaria'].errors?.['required'] && getProjectClientForm['concessionaria'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-3 p-fluid p-float-label mb-3">
                    <p-inputMask [(ngModel)]="projectClient.telefone" formControlName="telefone"
                        [ngClass]="cssValidator(getProjectClientForm['telefone'])" [unmask]="true"
                        mask="(99) 99999-9999" [readonly]="!projectClient.status">
                    </p-inputMask>
                    <label class="ml-1">* Contato 1</label>
                    <div *ngIf="getProjectClientForm['telefone'].errors?.['required'] && getProjectClientForm['telefone'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-3 p-fluid p-float-label mb-3">
                    <p-inputMask [(ngModel)]="projectClient.telefone2" formControlName="telefone2" [unmask]="true"
                        mask="(99) 99999-9999" [readonly]="!projectClient.status">
                    </p-inputMask>
                    <label class="ml-1">Contato 2</label>
                </div>
                <div class="col-12 md:col-3 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [ngClass]="cssValidator(getProjectClientForm['rg'])"
                        [(ngModel)]="projectClient.rg" formControlName="rg" [readonly]="!projectClient.status">
                    <label class="ml-1">* RG</label>
                    <div *ngIf="getProjectClientForm['rg'].errors?.['required'] && getProjectClientForm['rg'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-4 p-fluid p-float-label mb-3">
                    <p-inputMask [(ngModel)]="projectClient.cpf" formControlName="cpf"
                        [ngClass]="cssValidator(getProjectClientForm['cpf'])" [unmask]="true" mask="999.999.999-99"
                        [readonly]="!projectClient.status">
                    </p-inputMask>
                    <label class="ml-1">* CPF</label>
                    <div *ngIf="getProjectClientForm['cpf'].errors?.['required'] && getProjectClientForm['cpf'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-4 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText
                        [ngClass]="cssValidator(getProjectClientForm['tamanhoProjeto'])"
                        formControlName="tamanhoProjeto" [(ngModel)]="projectClient.tamanhoProjeto"
                        [readonly]="!projectClient.status">
                    <label class="ml-1">* Tamanho do Projeto</label>
                    <div *ngIf="getProjectClientForm['tamanhoProjeto'].errors?.['required'] && getProjectClientForm['tamanhoProjeto'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-4 p-fluid p-float-label mb-3">
                    <p-inputMask [(ngModel)]="projectClient.cep" formControlName="cep"
                        [ngClass]="cssValidator(getProjectClientForm['cep'])" [unmask]="true" mask="99999-999"
                        [readonly]="!projectClient.status" (onBlur)="getCep($event)">
                    </p-inputMask>
                    <label class="ml-1">* CEP</label>
                    <div *ngIf="getProjectClientForm['cep'].errors?.['required'] && getProjectClientForm['cep'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                    <div *ngIf="getProjectClientForm['cep'].invalid" class="p-error mt-1">
                        Cep inválido!
                    </div>
                </div>
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText formControlName="rua" [(ngModel)]="projectClient.rua"
                        formControlName="rua" [ngClass]="cssValidator(getProjectClientForm['rua'])"
                        [readonly]="!projectClient.status">
                    <label class="ml-1">* Rua</label>
                    <div *ngIf="getProjectClientForm['rua'].errors?.['required'] && getProjectClientForm['rua'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-2 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [ngClass]="cssValidator(getProjectClientForm['numero'])"
                        [(ngModel)]="projectClient.numero" formControlName="numero" [readonly]="!projectClient.status"
                        (blur)="localization()">
                    <label class="ml-1">* Número</label>
                    <div *ngIf="getProjectClientForm['numero'].errors?.['required'] && getProjectClientForm['numero'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [(ngModel)]="projectClient.complemento"
                        formControlName="complemento" [readonly]="!projectClient.status">
                    <label class="ml-1"> Complemento</label>
                </div>
                <div class="col-12 md:col-6 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText formControlName="cidade"
                        [ngClass]="cssValidator(getProjectClientForm['cidade'])" [(ngModel)]="projectClient.cidade"
                        [readonly]="!projectClient.status">
                    <label class="ml-1">* Cidade</label>
                    <div *ngIf="getProjectClientForm['cidade'].errors?.['required'] && getProjectClientForm['cidade'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-2 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [ngClass]="cssValidator(getProjectClientForm['estado'])"
                        [(ngModel)]="projectClient.estado" formControlName="estado" [readonly]="!projectClient.status">
                    <label class="ml-1">* Estado</label>
                    <div *ngIf="getProjectClientForm['estado'].errors?.['required'] && getProjectClientForm['estado'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 md:col-5 p-fluid p-float-label mb-3">
                    <input class="textUppercase" pInputText [ngClass]="cssValidator(getProjectClientForm['bairro'])"
                        [(ngModel)]="projectClient.bairro" formControlName="bairro" [readonly]="!projectClient.status">
                    <label class="ml-1">* Bairro</label>
                    <div *ngIf="getProjectClientForm['bairro'].errors?.['required'] && getProjectClientForm['bairro'].touched"
                        class="p-error mt-1">
                        Campo obrigatório!
                    </div>
                </div>
                <div class="col-12 p-fluid" *ngIf="localization() != null && localization() != ''">
                    <iframe [src]="localization() | safe" height="400" width="100%" style="border:0;" allowfullscreen=""
                        loading="lazy" referrerpolicy="no-referrer-when-downgrade">
                    </iframe>
                </div>
            </div>
        </div>

        <div class="p-fluid col-12 mb-3">
            <label class="ml-1">Observações do Projeto</label>
            <textarea pInputTextarea rows="10" style="resize: none; height: 100%;" formControlName="observacao"
                [(ngModel)]="projectClient.observacao" [readonly]="index">
      </textarea>
        </div>

        <div class="col-12 md:col-4 p-fluid p-float-label mt-4">
            <p-dropdown [options]="responsiblesOptions" formControlName="responsavel"
                [(ngModel)]="projectClient.responsavel" optionLabel="label" optionValue="value" [filter]="true"
                filterBy="label" [readonly]="index" class="textUppercase"
                [ngClass]="cssValidator(getProjectClientForm['responsavel'])" filterPlaceholder="Digite para filtrar">
            </p-dropdown>
            <label class="ml-1">* Gerente de Suporte</label>
            <div *ngIf="getProjectClientForm['responsavel'].errors?.['required'] && getProjectClientForm['responsavel'].touched"
                class="p-error mt-1">
                Campo obrigatório!
            </div>
        </div>

        <div class="col-12 md:col-4 flex mt-5 ml-auto justify-content-end" *ngIf="projectClientId == 0"
            [style]="projectClientId != 0 ? 'display: none' : ''">
            <p-button [label]="textButton" (click)="showDialog(projectClient)"></p-button>
        </div>

        <div class="col-12 md:col-4 flex mt-5 ml-auto justify-content-end" *ngIf="projectClientId != 0"
            [style]="projectClientId == 0 ? 'display: none' : ''">
            <p-button [label]="textButton" (click)="showDialog(projectClient)"
                [style]="!projectClient.status ? 'display: none;' : ''">
            </p-button>
        </div>
    </form>
</div>

<div class="card flex justify-content-center" class="textUppercase">
    <p-dialog header="Header" [(visible)]="visible" [style]="{ width: '50vw' }" [modal]="true" [draggable]="false"
        [resizable]="false">
        <ng-template pTemplate="header">
            <span class="text-xl font-bold">Cadastro Projeto</span>
        </ng-template>
        <p>Projeto do Cliente: {{clientName}} {{textAction}}</p>
        <ng-template pTemplate="footer">
            <p-button icon="pi pi-check" (click)="visible = false" label="Sim" (click)="onSubmit()">
            </p-button>
            <p-button icon="pi pi-times" (click)="visible = false" label="Não"></p-button>
        </ng-template>
    </p-dialog>
</div>