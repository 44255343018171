import { Injectable } from "@angular/core";
import { ProfileEnum } from "../providers/Enums/profile-Enum";
import { PageDateRequest } from "../providers/models/pageDateRequest";
import { resetLoginRequest } from "../providers/models/resetLoginRequest";
import { Usuario } from "../providers/models/usuario";
import { HttpClientService } from "../providers/services/http-client.service";

@Injectable({
    providedIn: "root",
})
export class UsuarioService {
    constructor(private httpClient: HttpClientService) {}

    public getUsuarios(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", "");
    }

    public getUsuariosPorDatas(
        pageDateRequest: PageDateRequest
    ): Promise<Usuario[]> {
        return this.httpClient.post(
            "/Usuario",
            `/usuarios/datas`,
            pageDateRequest
        );
    }

    public validarUsuario(resetLogin: resetLoginRequest): Promise<Usuario> {
        return this.httpClient.post(
            "/Usuario",
            `/validar/dados/usuario`,
            resetLogin
        );
    }

    public getVendedores(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/vendedores`);
    }

    public getListaVendedores(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/vendedores-lista`);
    }

    public getIndicadores(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/todos/indicadores`);
    }

    public getIndicadoresPorVendedorId(
        cadastradoPorId: number
    ): Promise<Usuario[]> {
        return this.httpClient.get(
            "/Usuario",
            `/indicadores-vendedor/${cadastradoPorId}`
        );
    }

    public getOperadoresTecnicos(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/operadores-tecnicos`);
    }

    public getGerentesSuporte(): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/gerentes-suporte`);
    }

    public getUsuarioPorId(usuarioId: number): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/${usuarioId}`);
    }

    public getUsuarioPorNome(usuarioNome: string): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/nome/${usuarioNome}`);
    }

    public getUsuarioPorNomeSobrenome(
        usuarioNomeSobrenome: string
    ): Promise<Usuario> {
        return this.httpClient.get(
            "/Usuario",
            `/nome-sobrenome/${usuarioNomeSobrenome}`
        );
    }

    public getUsuariosPorNomePerfil(perfil: ProfileEnum): Promise<Usuario[]> {
        return this.httpClient.get("/Usuario", `/por/nome/perfil/${perfil}`);
    }

    public postUsuario(usuario: Usuario): Promise<Usuario> {
        return this.httpClient.post("/Usuario", "", usuario);
    }

    public putUsuario(
        usuario: Usuario,
        usuarioLogadoId: number
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar-usuario/${usuario.id}/${usuarioLogadoId}`,
            usuario
        );
    }

    public putSenhaUsuario(
        usuario: Usuario,
        novaSenha: string
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar/senha/usuario/${usuario.id}/${novaSenha}`,
            usuario
        );
    }

    public postSenhaUsuario(
        usuarioId: number,
        senha: string
    ): Promise<Usuario> {
        return this.httpClient.post(
            "/Usuario",
            `/cadastrar/senha/usuario/${usuarioId}/${senha}`,
            true
        );
    }

    public putAlterarStatusUsuario(
        usuario: Usuario,
        status: boolean,
        usuarioLogadoId: number
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar-status/${status}/${usuarioLogadoId}`,
            usuario
        );
    }

    public putAtualizarStatusFerramentaUsuario(
        usuarioId: number,
        statusFerramenta: boolean,
        usuarioLogadoId: number
    ): Promise<Usuario> {
        return this.httpClient.put(
            "/Usuario",
            `/atualizar/status/ferramenta/${statusFerramenta}/${usuarioId}/${usuarioLogadoId}`,
            usuarioId
        );
    }

    public reenvioEmailCadastroUsuario(usuarioId: number): Promise<boolean> {
        return this.httpClient.post(
            "/Usuario",
            `/reenvio/email/cadastro/${usuarioId}`,
            true
        );
    }

    public deleteUsuario(
        usuarioId: number,
        usuarioLogadoId: number,
        indice?: boolean
    ): Promise<Usuario> {
        return this.httpClient.delete(
            "/Usuario",
            `/id/${indice}/${usuarioId}/${usuarioLogadoId}`
        );
    }

    public salvarImagem(
        usuarioId: number,
        usuarioLogadoId: number,
        file: File
    ): Promise<Usuario> {
        const fileToUpload = file as File;
        const formData = new FormData();
        formData.append("file", fileToUpload);

        return this.httpClient.post(
            "/Usuario",
            `/upload-image/${usuarioId}/${usuarioLogadoId}`,
            formData
        );
    }

    public verificarEmail(email: string): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/email/${email}`);
    }

    public verificarCpf(cpf: string): Promise<Usuario> {
        return this.httpClient.get("/Usuario", `/cpf/${cpf}`);
    }

    public allListDownloadVendedores(): Promise<any> {
        return this.httpClient.get("/Usuario", `/export-vendedores`, {
            responseType: "blob",
        });
    }

    public allListDownloadUsuarios(): Promise<any> {
        return this.httpClient.get("/Usuario", `/export-usuarios`, {
            responseType: "blob",
        });
    }
}
