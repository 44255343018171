import { Injectable } from "@angular/core";
import { HttpClientService } from "../providers/services/http-client.service";
import { Parcela } from "../providers/models/parcela";

@Injectable({
    providedIn: "root",
})
export class ParcelaService {
    constructor(private httpClient: HttpClientService) {}

    public getParcelas(): Promise<Parcela[]> {
        return this.httpClient.get("/Parcela", `/lista`);
    }

    public registerNewInstallment(
        request: Parcela,
        userLoggedId: number
    ): Promise<Parcela> {
        return this.httpClient.post("/Parcela", `/${userLoggedId}`, request);
    }
}
