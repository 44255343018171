<div class="full-page c-background flex flex-column justify-content-around">

    <div class="flex justify-content-center align-items-end relativea">
        <span class="text-bg"></span>
        <div class="default-position nordsol_erro n-height absolute">
        </div>
    </div>


    <div class="c-nordsol text-center text-lg font-semibold">
        Estamos muito tristes! Não encontramos o que estava procurando :(
        <div class="flex justify-content-center">
            <button pButton class="p-button-link p-button-secondary" label="voltar" (click)="goback()">
            </button>
        </div>
    </div>

</div>