import { NgModule } from "@angular/core";
import { CpfPipe } from "./cpf.pipe";
import { DocumentNumberPipe } from "./number-formatting/document-number.pipe";
import { PhoneNumberPipe } from "./number-formatting/phone-number.pipe";
import { SafeUrlPipe } from "./safe-url.pipe";
import { SafePipe } from "./safe.pipe";
import { CepPipe } from "./cep.pipe";
import { CnpjPipe } from "./cnpj.pipe";

const PIPES = [
    DocumentNumberPipe,
    PhoneNumberPipe,
    SafeUrlPipe,
    SafePipe,
    CpfPipe,
    CnpjPipe,
    CepPipe
];

@NgModule({
    declarations: PIPES,
    exports: PIPES
  })
  export class PipesModule { }
  