export enum ProjectTypeEnum {
    AumentoCarga,
    ProjetoGd,
    PadraoEntrada   
}

export const ProjectTypeEnumLabel = new Map<number, string>([
    [ProjectTypeEnum.AumentoCarga, 'Aumento de Carga'],
    [ProjectTypeEnum.ProjetoGd, 'Projeto Gd'],
    [ProjectTypeEnum.PadraoEntrada, 'Padrão de Entrada']
]);