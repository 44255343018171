<div class="title-page flex align-items-center col-12 md:col-6 mb-0">
    <app-button-back></app-button-back>
    <span class="ml-2">Relatórios</span>
</div>
<div class="card">
    <p-tabView styleClass="tabview-custom">
        <p-tabPanel header="Cliente">
            <ng-template pTemplate="header">
                <i class="pi pi-users mr-1"></i>
                <span>Cliente</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <!-- <p-tabPanel header="Cliente Indicado">
            <ng-template pTemplate="header">
                <i class="pi pi-user-plus mr-1"></i>
                <span>Cliente Indicado</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Manutenção">
            <ng-template pTemplate="header">
                <i class="pi pi-wrench mr-1"></i>
                <span>Manutenção</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="O.S">
            <ng-template pTemplate="header">
                <i class="pi pi-file-edit mr-1"></i>
                <span>O.S</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Produto">
            <ng-template pTemplate="header">
                <i class="pi pi-cart-plus mr-1"></i>
                <span>Produto</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Material O.S">
            <ng-template pTemplate="header">
                <i class="pi pi-copy mr-1"></i>
                <span>Material O.S</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Projeto">
            <ng-template pTemplate="header">
                <i class="pi pi-table mr-1"></i>
                <span>Projeto</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Restreio">
            <ng-template pTemplate="header">
                <i class="pi pi-truck mr-1"></i>
                <span>Rastreio</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Ferramenta">
            <ng-template pTemplate="header">
                <i class="pi pi-list mr-1"></i>
                <span>Ferramenta</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel>
        <p-tabPanel header="Veículo">
            <ng-template pTemplate="header">
                <i class="pi pi-car mr-1"></i>
                <span>Veículo</span>
            </ng-template>
            <app-clientes></app-clientes>
        </p-tabPanel> -->
    </p-tabView>
</div>
