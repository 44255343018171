import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import {
    ProfileEnum,
    ProfileEnumLabel,
} from "src/app/providers/Enums/profile-Enum";
import { Cliente } from "src/app/providers/models/cliente";
import { MessageService } from "primeng/api";
import { ClientService } from "src/app/services/client.service";
import { ConvertValues } from "src/app/services/convert-values.service";
import { StepEnum, StepEnumLabel } from "src/app/providers/Enums/etapa-Enum";
import { CoreService } from "src/app/services/core.service";
import {
    SituationEnum,
    SituationEnumLabel,
} from "src/app/providers/Enums/situacao-Enum";

@Component({
    selector: "app-project-seller-list",
    templateUrl: "./project-seller-list.component.html",
    styleUrls: ["./project-seller-list.component.scss"],
})
export class ProjectSellerListComponent implements OnInit {
    vendedorId: number = 0;
    clienteNome: string = "";
    vendedorNome: string = "";
    clienteId: number = 0;
    projetoId: number = 0;
    first: number = 0;
    rows: number = 9;
    convertValues = ConvertValues;
    clientes: Cliente[] = [];
    returnedArray: Cliente[] = [];
    cliente = {} as Cliente;
    perfil: number = 0;
    situacaoProjeto: number = 0;

    constructor(
        private messageService: MessageService,
        private router: Router,
        private colorService: CoreService,
        private authService: AuthService,
        private activatedRouter: ActivatedRoute,
        private clienteService: ClientService
    ) {
        this.vendedorId = +this.activatedRouter.snapshot.paramMap.get("id")!;
        this.vendedorNome = this.activatedRouter.snapshot.paramMap.get("nome")!;
        this.situacaoProjeto =
            +this.activatedRouter.snapshot.paramMap.get("situacao")!;
        this.perfil = this.authService.perfil();

        if (this.perfil == ProfileEnum.Indicador) window.history.back();
    }

    ngOnInit() {
        this.buscarClientes();
    }

    buscarNomePerfil(nomePerfil: ProfileEnum): string {
        return ProfileEnumLabel.get(nomePerfil)!;
    }

    async buscarClientes() {
        await this.clienteService
            .getClientsByNameSeller(this.vendedorNome)
            .then(
                (result) => {
                    this.clientes = result.filter(
                        (c) => c.situacao == this.situacaoProjeto && c.isActive
                    );
                    this.returnedArray = this.clientes.slice(0, 9);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar listar os Projetos.`,
                    });
                }
            )
            .finally();
    }

    getSituation(situationClient: SituationEnum): string {
        return SituationEnumLabel.get(situationClient)!;
    }

    getSituationColor(situation: SituationEnum): string {
        return this.colorService.getSituationColor(situation);
    }

    visualizarCliente(clienteId: number) {
        this.router.navigateByUrl(`/app/cliente/detalhes/${clienteId}`);
    }

    onPageChange(event: any): void {
        this.returnedArray = this.clientes.slice(
            event.first,
            event.first + event.rows
        );
    }
}
