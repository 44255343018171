export enum SituationEnum {
    Analise,
    Documentos,
    Aprovado,
    Reprovado
}

export const SituationEnumLabel = new Map<number, string>([
    [SituationEnum.Analise, 'Análise'],
    [SituationEnum.Documentos, 'Documentos'],
    [SituationEnum.Aprovado, 'Aprovado'],
    [SituationEnum.Reprovado, 'Reprovado']
])