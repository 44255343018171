import { Injectable } from '@angular/core';
import { HttpClientService } from "../providers/services/http-client.service";
import { ProjetoCliente } from '../providers/models/projeto-cliente';
import { PageDateRequest } from '../providers/models/pageDateRequest';

@Injectable({
  providedIn: "root",
})
export class ProjectClientService {
  
  constructor(private httpClient: HttpClientService) {}

  public getProjectsClients(): Promise<ProjetoCliente[]> {
    return this.httpClient.get('/ProjetoCliente', '');
  }

  public getProjectsClientsByDates(pageDateRequest: PageDateRequest): Promise<ProjetoCliente[]> {
    return this.httpClient.post('/ProjetoCliente', `/projetosClientes/datas`, pageDateRequest);
  }

  public getProjectsClientsByStatus(status: boolean): Promise<ProjetoCliente[]> {
    return this.httpClient.get('/ProjetoCliente',  `/status/${status}`);
  }

  public getProjectsClientsOperatorName(operadorTecnico: string): Promise<ProjetoCliente[]> {
    return this.httpClient.get('/ProjetoCliente', `/operador/tecnico/${operadorTecnico}`);
  }

  public getProjectClientById(projetoClienteId: number): Promise<ProjetoCliente> {
    return this.httpClient.get('/ProjetoCliente',  `/${projetoClienteId}`);
  }

  public postProjectClient(projetoCliente: ProjetoCliente, usuarioLogadoId: number): Promise<ProjetoCliente> {
    return this.httpClient.post('/ProjetoCliente', `/cadastrar-projeto/${usuarioLogadoId}`, projetoCliente);
  }

  public putProjectClient(projetoCliente: ProjetoCliente, usuarioLogadoId: number): Promise<ProjetoCliente> {
    return this.httpClient.put('/ProjetoCliente', `/atualizar-projeto/${projetoCliente.id}/${usuarioLogadoId}`, projetoCliente);
  }

  public deleteProjetoCliente(projetoClienteId: number, usuarioLogadoId: number): Promise<any> {
    return this.httpClient.delete('/ProjetoCliente', `/id/${projetoClienteId}/${usuarioLogadoId}`);
  }

  public openProjectClient(projetoCliente: ProjetoCliente, status: boolean, usuarioLogadoId: number): Promise<any> {
    return this.httpClient.put('/ProjetoCliente', `/reabrir-projeto/${projetoCliente.id}/${status}/${usuarioLogadoId}`, projetoCliente);
  }

  public saveImage(projetoClienteId: number, file: File): Promise<ProjetoCliente> {
    const fileToUpload = file as File;
    const formData = new FormData();
    formData.append('file', fileToUpload);

    return this.httpClient.post('/ProjetoCliente', `/upload-image/${projetoClienteId}`, formData);
  }
  
  public getProjectsClientsByClientName(clienteNome: string): Promise<ProjetoCliente[]> {
    return this.httpClient.get('/ProjetoCliente', `/projetos-cliente/${clienteNome}`);
  }

  public saveRegistry(projetoClienteId: number, registro: string, usuarioLogadoId: number, file: File): Promise<ProjetoCliente> {
    const fileToUpload = file as File;
    const formData = new FormData();
    formData.append('file', fileToUpload);

    return this.httpClient.post('/ProjetoCliente', `/upload-registro/${projetoClienteId}/${registro}/${usuarioLogadoId}`, formData);
  }

  public allListDownload(): Promise<any> {    
    return this.httpClient.get('/ProjetoCliente', '/export', { responseType: 'blob' });
  }  
}