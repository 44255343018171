import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PdfViewerModule } from "ng2-pdf-viewer";
import { ButtonModule } from "primeng/button";
import { MessageModule } from "primeng/message";
import { ModalPdfComponent } from "./modal-pdf.component";
import { PipesModule } from "../pipes/pipes.module";
import { ToastModule } from "primeng/toast";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ButtonModule,
        ToastModule,
        PipesModule,
        ReactiveFormsModule,
        MessageModule,
        PdfViewerModule,
    ],
    declarations: [ModalPdfComponent],
})
export class ModalPdfModule {}
