import { Component, Input, OnInit } from "@angular/core";
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators,
} from "@angular/forms";
import { validaCPF } from "ngx-cnpj-cpf-validator";
import { ProjectService } from "src/app/services/project.service";
import { AuthService } from "src/app/services/auth.service";
import { Projeto } from "src/app/providers/models/projeto";
import { ProfileEnum } from "src/app/providers/Enums/profile-Enum";
import { MessageService } from "primeng/api";
import { DadosProjeto } from "src/app/providers/models/dados-projeto";
import { SpinnerService } from "src/app/services/spinner.service";

@Component({
    selector: "app-project-register",
    templateUrl: "./project-register.component.html",
    styleUrls: ["./project-register.component.scss"],
})
export class ProjectRegisterComponent implements OnInit {
    formProject!: FormGroup;
    project = {} as Projeto;
    @Input() projects: Projeto[] = [];
    @Input() projetcsData = {} as DadosProjeto;
    @Input() clientId: number = 0;
    projectId: number = 0;
    sellerId: number = 0;
    deleteProject: boolean = false;
    profile: number = 0;
    userLoggedId: number = 0;

    constructor(
        private projectService: ProjectService,
        private fb: FormBuilder,
        private authService: AuthService,
        private spinnerService: SpinnerService,
        private messageService: MessageService
    ) {
        this.createformProject();
        this.userLoggedId = this.authService.userId();
        this.profile = +this.authService.perfil();
        if (this.profile == ProfileEnum.Indicador) window.history.back();
    }

    ngOnInit() {
        this.projects;
    }

    isAdm() {
        return this.profile == ProfileEnum.Administrador;
    }

    isViewEdit() {
        return (
            this.profile == ProfileEnum.GerenteDeFormalizacao ||
            this.profile == ProfileEnum.Administrador
        );
    }

    createformProject() {
        this.formProject = this.fb.group({
            id: [null],
            nome: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(60),
                ],
            ],
            rg: [null, Validators.required],
            cpf: [null, Validators.required],
            potenciaSistema: [null, Validators.required],
            potenciaInversor: [null, Validators.required],
            quantidadeDePaineis: [null, Validators.required],
            marcaInversor: [null, Validators.required],
            marcaPaineis: [null, Validators.required],
            potenciaPaineis: [null, Validators.required],
            observacao: [null],
            vendedor: [this.projetcsData.vendedorNome, Validators.required],
            vendedorId: [this.projetcsData.vendedorId, Validators.required],
            clienteId: [this.clientId, Validators.required],
            cadastradoPor: [this.authService.userFullName()],
        });
    }

    onSubmitProject(): void {
        this.formProject
            .get("vendedor")
            ?.setValue(this.projetcsData.vendedorNome);
        this.formProject
            .get("vendedorId")
            ?.setValue(this.projetcsData.vendedorId);
        this.formProject.get("clienteId")?.setValue(this.clientId);
        if (this.formProject.invalid) {
            Object.keys(this.getProjectForm).forEach((key) => {
                this.formProject.get(key).markAsTouched();
            });
            return this.messageService.add({
                severity: "warn",
                summary: "Atenção!",
                detail: `Formulário com campos inválidos.`,
            });
        } else {
            this.postProject();
        }
    }

    onUpdateProject(project: Projeto): void {
        this.putProject(project);
    }

    cssValidator(campoForm: FormControl | AbstractControl): any {
        return { "ng-invalid ng-dirty": campoForm.errors && campoForm.touched };
    }

    get getProjectForm() {
        return this.formProject.controls;
    }

    async postProject() {
        this.spinnerService.show();
        this.project = { ...this.formProject.value };
        await this.projectService
            .postProject(this.project, this.authService.userId())
            .then(
                (retorno: Projeto) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `Projeto: ${retorno.potenciaSistema} KWP cadastrado com sucesso.`,
                        life: 2000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar salvar projeto do Cliente: ${this.projetcsData.clienteNome.toUpperCase()}.`,
                    });
                }
            )
            .finally(() => this.spinnerService.hide());
    }

    async putProject(project: Projeto) {
        this.spinnerService.show();
        await this.projectService
            .putProject(project, this.authService.userId())
            .then(
                (retorno: Projeto) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `Projeto: ${retorno.potenciaSistema} KWP atualizado com sucesso.`,
                        life: 2000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar atualizar projeto.`,
                    });
                }
            )
            .finally(() => this.spinnerService.hide());
    }

    cpfValido(cpfCnpj: string) {
        if (cpfCnpj != "" && cpfCnpj != undefined) {
            if (validaCPF(cpfCnpj)) {
                this.messageService.add({
                    severity: "success",
                    summary: "Sucesso!",
                    detail: `CPF válido para cadastro.`,
                });
            } else {
                this.messageService.add({
                    severity: "warn",
                    summary: "Alerta!",
                    detail: `CPF inválido, Digite um CPF válido.`,
                    life: 4000,
                });
                this.formProject.get("cpf").setValue(null);
                return;
            }
        }
    }

    openModalDeleteProject(project: Projeto): void {
        this.projectId = project.id;
        this.sellerId = project.vendedorId;
        this.deleteProject = true;
    }

    async deleteProjectClient() {
        await this.projectService
            .deleteProject(
                this.projectId,
                this.clientId,
                this.userLoggedId,
                this.sellerId
            )
            .then(
                (retorno: any) => {
                    this.messageService.add({
                        severity: "success",
                        summary: "Sucesso!",
                        detail: `${retorno.message}`,
                        life: 5000,
                    });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `${error.error}`,
                        life: 5000,
                    });
                }
            )
            .finally();
    }

    setSystemPower() {
        const quantityPainels = this.formProject.get(
            "quantidadeDePaineis"
        )?.value;
        const painelsPower = this.formProject.get("potenciaPaineis")?.value;

        if (quantityPainels != null && quantityPainels != undefined) {
            const systemPower = quantityPainels * (painelsPower * 0.001);
            if (painelsPower != null)
                this.formProject.get("potenciaSistema")?.setValue(systemPower);
        }
    }
}
