import { Component, OnInit } from "@angular/core";
import { MessageService } from "primeng/api";
import { EstadosBr } from "src/app/providers/models/estadosBr";
import { Usuario } from "src/app/providers/models/usuario";
import { ClientService } from "src/app/services/client.service";
import { UserService } from "src/app/services/user.service";
import { environment } from "src/environments/environment";
import { ConvertValues } from "src/app/services/convert-values.service";
import { AuthService } from "src/app/services/auth.service";
import { ClientsReportList } from "src/app/providers/models/clients-report-list";
import {
    ClientTypeEnum,
    ClientTypeEnumLabel,
} from "src/app/providers/Enums/tipo-cliente";
import { ClientsFilterRequest } from "src/app/providers/models/clients-filter-request";
import { StepEnumLabel } from "src/app/providers/Enums/etapa-Enum";
import { SituationEnumLabel } from "src/app/providers/Enums/situacao-Enum";
import { SpinnerService } from "src/app/services/spinner.service";
import {
    WithdrawalEnumLabel,
    WithdrawalEnum,
} from "src/app/providers/Enums/desistencia-Enum";

interface Drops {
    name: string;
    value: number | string;
}

interface State {
    name: string;
    value: string;
}
@Component({
    selector: "app-clientes",
    templateUrl: "./clientes.component.html",
    styleUrls: ["./clientes.component.scss"],
})
export class ClientesComponent implements OnInit {
    clientId: number = 0;
    maxDate = new Date();
    convertValues = ConvertValues;
    sellers: Usuario[] = [];
    clientsFull: ClientsReportList[] = [];
    returnedArray: ClientsReportList[] = [];
    isFilter: boolean = false;
    desistenceOptions: any[] = [
        { label: "Não", value: false },
        { label: "Sim", value: true },
    ];
    spinnerExcelList: boolean = false;
    spinnerPdfClient: boolean = false;
    spinnerPdfList: boolean = false;
    profileType: number = 0;
    states = EstadosBr;
    noPicture = environment.noPicture;
    first: number = 0;
    rows: number = 9;
    totalPrice: number = 0;
    statesOptions: State[] = [];
    personsOptions: Drops[] = [];
    withdrawalOptions: Drops[] = [];
    sellersOptions: Drops[] = [];

    clientsFilterRequest: ClientsFilterRequest;
    dateStart: Date;
    dateEnd: Date;

    constructor(
        private contextService: AuthService,
        private clientService: ClientService,
        private spinnerService: SpinnerService,
        private messageService: MessageService,
        private userService: UserService
    ) {
        this.profileType = this.contextService.getUserPerfil();
    }

    async ngOnInit() {
        this.setClientsRequestFilter();
        await this.getClients();
        await this.getSellers();
        await this.setOptions();
    }

    setClientsRequestFilter() {
        this.clientsFilterRequest = {
            dataInicio: null,
            dataFim: null,
            cidade: "",
            isDesistiu: false,
            estado: "",
            tipo: null,
            motivoDesistencia: [],
            vendedores: [],
            andamento: false,
            aconcluir: false,
            concluido: false,
            enviado: false,
            analise: false,
            documentos: false,
            aprovado: false,
            reprovado: false,
        };
    }

    setOptions() {
        this.statesOptions.push({ name: "Selecione o Estado", value: null });
        this.states.map((state) => {
            this.statesOptions.push({ name: state.nome, value: state.nome });
        });

        this.sellers.map((seller) => {
            this.sellersOptions.push({ name: seller.nome, value: seller.nome });
        });

        this.personsOptions = [
            { name: "Selecione o Tipo", value: null },
            { name: ClientTypeEnumLabel.get(0), value: ClientTypeEnum.PF },
            { name: ClientTypeEnumLabel.get(1), value: ClientTypeEnum.PJ },
        ];

        this.withdrawalOptions = [
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo1),
                value: WithdrawalEnum.Motivo1,
            },
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo2),
                value: WithdrawalEnum.Motivo2,
            },
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo3),
                value: WithdrawalEnum.Motivo3,
            },
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo4),
                value: WithdrawalEnum.Motivo4,
            },
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo5),
                value: WithdrawalEnum.Motivo5,
            },
            {
                name: WithdrawalEnumLabel.get(WithdrawalEnum.Motivo6),
                value: WithdrawalEnum.Motivo6,
            },
        ];
    }

    getStep(step: number): string {
        return StepEnumLabel.get(step);
    }

    getSituation(situation: number): string {
        return SituationEnumLabel.get(situation);
    }

    getSituationColor(situation: string): string {
        let returnSituation: string = "";
        switch (situation) {
            case "Análise":
                returnSituation = "warning";
                break;
            case "Documentos":
                returnSituation = "info";
                break;
            case "Aprovado":
                returnSituation = "success";
                break;
            case "Reprovado":
                returnSituation = "danger";
                break;
            default:
                returnSituation = "secondary";
        }
        return returnSituation;
    }

    getStepColor(step: string): string {
        let returnStep: string = "";
        switch (step) {
            case "Sem Projeto":
                returnStep = "primary";
                break;
            case "Em Andamento":
                returnStep = "warning";
                break;
            case "A Concluir":
                returnStep = "danger";
                break;
            case "Concluído":
                returnStep = "success";
                break;
            default:
                returnStep = "info";
        }
        return returnStep;
    }

    async getClients() {
        this.spinnerService.show();
        await this.clientService
            .getAllClientsReport(this.clientsFilterRequest)
            .then(
                (result) => {
                    this.clientsFull = result;
                    this.returnedArray = this.clientsFull.slice(0, 9);
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar listar Clientes: ${error.message}`,
                        life: 3000,
                    });
                }
            )
            .finally(() => {
                this.totalPrice = this.clientsFull.reduce(
                    (acc, cliente) => acc + parseFloat(cliente.valorProjeto),
                    0
                );
                console.log(this.totalPrice);
                this.isFilter = !Number.isNaN(this.totalPrice) ? true : false;
                this.spinnerService.hide();
            });
    }

    async setSeller(event: any) {
        if (event.value)
            this.clientsFilterRequest.vendedores = event.value.map(
                (x: any) => x.value
            );
        else this.clientsFilterRequest.vendedores = [];
    }

    async getSellers() {
        this.spinnerService.show();
        await this.userService
            .getSellers()
            .then(
                (result) => {
                    this.sellers = result;
                },
                (error: any) => {
                    this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar filtrar Vendedores: ${error.message}`,
                        life: 3000,
                    });
                }
            )
            .finally(() => this.spinnerService.hide());
    }

    setClientsByType(event: any) {
        if (event.value != null) this.clientsFilterRequest.tipo = event.value;
        else this.clientsFilterRequest.tipo = event.value;
    }

    setWithdrawal(event: any) {
        if (event.value)
            this.clientsFilterRequest.motivoDesistencia = event.value.map(
                (x: any) => x.value
            );
        else this.clientsFilterRequest.motivoDesistencia = [];
    }

    filterByDates(date: Date, indice: number): void {
        if (indice == 0)
            this.clientsFilterRequest.dataInicio = date.toDateString();
        else this.clientsFilterRequest.dataFim = date.toDateString();
    }

    clearSearch() {
        this.dateStart = null;
        this.dateEnd = null;
        this.clientsFilterRequest.dataInicio = "";
        this.clientsFilterRequest.dataFim = "";
        this.clientsFilterRequest.cidade = "";
        this.clientsFilterRequest.estado = "";
        this.statesOptions = [];
        this.personsOptions = [];
        this.withdrawalOptions = [];
        this.sellersOptions = [];
        this.setOptions();
        this.getClients();
    }

    async downloadPdfClient(clientId: number, clientName: string) {
        this.spinnerService.show();
        this.spinnerPdfClient = true;
        var createdAt = new Date();
        var createdAtformatter = createdAt.toLocaleDateString();
        this.messageService.add({
            severity: "info",
            summary: "Aguarde!",
            detail: `Iniciando baixa de relatório.`,
            life: 3000,
        });

        let filename = `Relatório Cliente (${ConvertValues.convertNameCapitalize(
            clientName
        )}) - ${createdAtformatter}.pdf`;
        await this.clientService
            .getPdfClient(clientId)
            .then(
                (result) => {
                    this.handleFile(result, filename);
                },
                (error: any) => {
                    return this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar baixar relatório: ${error.message}`,
                        life: 3000,
                    });
                }
            )
            .finally(() => {
                this.spinnerService.hide();
                this.spinnerPdfClient = false;
            });
    }

    async getExcelList() {
        this.spinnerService.show();
        this.spinnerExcelList = true;
        var createdAt = new Date();
        var createdAtformatter = createdAt.toLocaleDateString();
        this.messageService.add({
            severity: "info",
            summary: "Aguarde!",
            detail: `Iniciando baixa de relatório.`,
            life: 3000,
        });

        let filename = `Relatório Clientes - ${createdAtformatter}.xlsx`;
        await this.clientService
            .allListDownloadClientsReport(this.clientsFull)
            .then(
                (result) => {
                    this.handleFile(result, filename);
                },
                (error: any) => {
                    return this.messageService.add({
                        severity: "error",
                        summary: "Erro!",
                        detail: `Erro ao tentar baixar relatório: ${error.message}`,
                        life: 3000,
                    });
                }
            )
            .finally(() => {
                this.spinnerService.hide();
                this.spinnerExcelList = false;
            });
    }

    handleFile(response: any, filename: string) {
        const file = new Blob([response], {
            type: response.type,
        });

        const nav = window.navigator as any;
        if (nav.navigator && nav.msSaveOrOpenBlob)
            return nav.msSaveOrOpenBlob(file);

        const blob = window.URL.createObjectURL(file);

        const link = document.createElement("a");
        link.href = blob;
        link.download = filename;

        link.dispatchEvent(
            new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
            })
        );

        setTimeout(() => {
            this.messageService.add({
                severity: "success",
                summary: "Sucesso!",
                detail: `Baixa de relatório efetuada com sucesso.`,
                life: 3000,
            });
            window.URL.revokeObjectURL(blob);
            link.remove();
        }, 100);
    }

    onPageChange(event: any) {
        this.returnedArray = this.clientsFull.slice(
            event.first,
            event.first + event.rows
        );
    }

    desistencia(desistencia: WithdrawalEnum): string {
        return WithdrawalEnumLabel.get(desistencia)!;
    }
}
