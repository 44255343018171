import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
} from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { MessageService } from "primeng/api";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpContext } from "../models/http-context";
import { HttpHandledError } from "../models/http-handled-error";
import { ContextService } from "./context.service";

@Injectable({
    providedIn: "root",
})
export class HttpClientService implements OnDestroy {
    constructor(
        private messageService: MessageService,
        private http: HttpClient,
        private contextService: ContextService
    ) {}

    ngOnDestroy(): void {
        this.messageService.clear();
    }

    async get(
        servicePath: string,
        endpoint: string,
        request: any = null,
        hasToken: boolean = true,
        text: boolean = false
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );

        let params: {};
        if (request) {
            request.sortOrder = 1;
            params = JSON.stringify({ request: request });
        }

        if (text) {
            return new Observable((observer) => {
                this.http
                    .get(httpContext?.url, {
                        headers: httpContext.options,
                        responseType: "text",
                        params: params,
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        } else {
            return new Observable((observer) => {
                this.http
                    .get(httpContext?.url, {
                        headers: httpContext.options,
                        params: request,
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            if (errorResponse.status == 400) {
                                observer.error(errorResponse.error);
                                observer.complete();
                                return;
                            }
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        }
    }

    async getFile(
        servicePath: string,
        endpoint: string,
        request: any = null,
        hasToken: boolean = true,
        text: boolean = false
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );

        let params: {};
        if (request) {
            request.sortOrder = 1;
            params = JSON.stringify({ request: request });
        }

        if (text) {
            return new Observable((observer) => {
                this.http
                    .get(httpContext?.url, {
                        headers: httpContext.options,
                        responseType: "blob",
                        params: params,
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        } else {
            return new Observable((observer) => {
                this.http
                    .get(httpContext?.url, {
                        headers: httpContext.options,
                        responseType: "blob",
                        params: request,
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        }
    }

    async extractExcelFile(
        servicePath: string,
        endpoint: string,
        request: any = null,
        hasToken: boolean = true,
        text: boolean = false
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );

        if (text) {
            return new Observable((observer) => {
                this.http
                    .get(httpContext?.url, {
                        headers: httpContext.options,
                        responseType: "text",
                        params: request,
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        } else {
            return new Observable((observer) => {
                this.http
                    .post(httpContext?.url, request, {
                        headers: httpContext.options,
                        responseType: "blob",
                    })
                    .pipe()
                    .subscribe(
                        (response: any) => {
                            observer.next(response);
                            observer.complete();
                        },
                        async (errorResponse: HttpErrorResponse) => {
                            if (errorResponse.status == 400) {
                                observer.error(errorResponse.error);
                                observer.complete();
                                return;
                            }
                            const handledError =
                                this.handleError(errorResponse);
                            observer.error(handledError);
                            observer.complete();
                        }
                    );
            }).toPromise();
        }
    }

    async delete(
        servicePath: string,
        endpoint: string,
        hasToken: boolean = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            this.http
                .delete(httpContext?.url, { headers: httpContext.options })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        const handledError = this.handleError(errorResponse);
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    async postKm(endpoint: string, data: any): Promise<any> {
        const headers = new HttpHeaders({
            "Content-Type": "application/json",
        });

        return new Observable((observer) => {
            this.http
                .post(
                    `https://coonsulta-km.azurewebsites.net/${endpoint}`,
                    data,
                    { headers }
                )
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        if (errorResponse.status == 400) {
                            observer.error(errorResponse.error);
                            observer.complete();
                            return;
                        }
                        const handledError = this.handleError(errorResponse);
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    async post(
        servicePath: string,
        endpoint: string,
        data: any,
        hasToken: boolean = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            this.http
                .post(httpContext?.url, data, { headers: httpContext.options })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        if (errorResponse.status == 400) {
                            observer.error(errorResponse.error);
                            observer.complete();
                            return;
                        }
                        const handledError = this.handleError(errorResponse);
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    async put(
        servicePath: string,
        endpoint: string,
        data: any,
        hasToken: boolean = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            this.http
                .put(httpContext?.url, data, { headers: httpContext.options })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        const handledError = this.handleError(errorResponse);
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    async postFile(
        servicePath: string,
        endpoint: string,
        file: File,
        hasToken: boolean = true,
        showErrorMessage = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            let formData: FormData = new FormData();
            formData.append("File", file, file.name);
            this.http
                .post(httpContext?.url, formData, {
                    headers: httpContext.options,
                })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        const handledError = this.handleError(
                            errorResponse,
                            showErrorMessage
                        );
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    async postFileWithFormData(
        servicePath: string,
        endpoint: string,
        formData: FormData,
        hasToken: boolean = true,
        showErrorMessage = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            this.http
                .post(httpContext?.url, formData, {
                    headers: httpContext.options,
                })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        const handledError = this.handleError(
                            errorResponse,
                            showErrorMessage
                        );
                        //observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }

    private handleError(
        errorResponse: HttpErrorResponse,
        showErrorMessage = true
    ): HttpHandledError {
        if (
            errorResponse.error != null &&
            errorResponse.error?.data != null &&
            errorResponse.error?.data?.length > 0
        ) {
            this.messageService.add({
                severity: "error",
                summary: "Falha",
                detail: errorResponse.error,
            });
        } else if (errorResponse.error != null) {
            this.messageService.add({
                severity: "error",
                summary: "Falha",
                detail: errorResponse.error,
            });
        }

        const handledError: HttpHandledError = {
            statusCode: errorResponse.status,
            messageError: errorResponse.message,
            data: errorResponse.error?.data,
        };

        return handledError;
    }

    private getHttpContext(
        servicePath: string,
        endpoint: string,
        hasToken: boolean
    ): HttpContext {
        let httpContext: HttpContext = {
            url: environment.baseApiUrl + servicePath + endpoint,
            options: null,
        };

        const userInfo = this.contextService.getUserInfo();
        if (userInfo && (hasToken || userInfo?.token != null)) {
            if (userInfo?.token != null) {
                let headers = new HttpHeaders().set(
                    "authorization",
                    "Bearer " + userInfo?.token
                );
                headers.set("Content-Type", "application/json");
                httpContext.options = headers;
                localStorage.setItem("token", JSON.stringify(userInfo?.token));
                return httpContext;
            } else {
                this.messageService.add({
                    severity: "error",
                    summary: "Token Expirado",
                    detail: "O seu token expirou, faça o login novamente",
                    life: 5000,
                });
                this.contextService.logout();
                return null;
            }
        } else {
            let headers = new HttpHeaders();
            headers.append("Cache-Control", "no-cache");
            headers.append("Pragma", "no-cache");
            httpContext.options = headers;
            return httpContext;
        }
    }

    async patch(
        servicePath: string,
        endpoint: string,
        data?: any,
        hasToken: boolean = true
    ): Promise<any> {
        const httpContext = this.getHttpContext(
            servicePath,
            endpoint,
            hasToken
        );
        return new Observable((observer) => {
            this.http
                .patch(httpContext?.url, data, { headers: httpContext.options })
                .pipe()
                .subscribe(
                    (response: any) => {
                        observer.next(response);
                        observer.complete();
                    },
                    async (errorResponse: HttpErrorResponse) => {
                        const handledError = this.handleError(errorResponse);
                        observer.error(handledError);
                        observer.complete();
                    }
                );
        }).toPromise();
    }
}
