import { Injectable } from '@angular/core';
import { Projeto } from '../providers/models/projeto';
import { PageDatesRequest } from '../providers/models/pageDatesRequest';
import { HttpClientService } from '../providers/services/http-client.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(private httpClient: HttpClientService) { }
  
  public getProjects(): Promise<Projeto[]>{
    return this.httpClient.get('/Projeto', '');
  }

  public getProjectsByNameId(clienteProjetoId: string): Promise<Projeto[]>{
    return this.httpClient.get('/Projeto', `/clienteNome/projetoId/${clienteProjetoId}`);
  }

  public getProjectsByDates(pageDatesRequest: PageDatesRequest): Promise<Projeto[]> {
    return this.httpClient.post('/Projeto', `/projetos/datas`, pageDatesRequest);
  }

  public getProjectsByClientId(clienteId: number): Promise<Projeto[]>{
    return this.httpClient.get('/Projeto', `/cliente/${clienteId}`);
  }

  public getProjectByCpf(cpf: string): Promise<Projeto>{
    return this.httpClient.get('/Projeto', `/cpf/${cpf}`);
  }

  public getProjectByName(nome: string): Promise<Projeto>{
    return this.httpClient.get('/Projeto', `/nome/${nome}`);
  }

  public getProjectSeller(vendedor: string): Promise<Projeto>{
    return this.httpClient.get('/Projeto', `/vendedor/${vendedor}`);
  }  

  public getProjectsBySeller(vendedorId: number): Promise<Projeto[]>{
    return this.httpClient.get('/Projeto', `/projetos-vendedor/${vendedorId}`);
  }

  public getProjectById(projetoId: number): Promise<Projeto>{
    return this.httpClient.get('/Projeto', `/${projetoId}`);
  }

  public postProject(projeto: Projeto, usuarioId: number): Promise<Projeto> {
    return this.httpClient.post('/Projeto', `/cadastrar-projeto/${usuarioId}`, projeto);
  }

  public putProject(projeto: Projeto, usuarioId: number,): Promise<Projeto> {
    return this.httpClient.put('/Projeto', `/atualizar-projeto/${projeto.id}/${usuarioId}`, projeto);
  }
  
  public deleteProject(projetoId: number, clienteId: number, usuarioLogadoId: number, vendedorId: number): Promise<any> {
    return this.httpClient.delete('/Projeto', `/id/${projetoId}/${clienteId}/${usuarioLogadoId}/${vendedorId}`);
  }  

  public allListDownload(): Promise<any> {    
    return this.httpClient.get('/Projeto', `/projetos-export`, { responseType: 'blob' });
  }  
}