import { Injectable } from "@angular/core";
import { StepEnum } from "../providers/Enums/etapa-Enum";
import { SituationEnum } from "../providers/Enums/situacao-Enum";
import { Cliente } from "../providers/models/cliente";
import { PageDatesRequest } from "../providers/models/pageDatesRequest";
import { HttpClientService } from "../providers/services/http-client.service";
import { UsuarioRequest } from "../providers/models/usuarioRequest";
import { ClienteFilterResponse } from "../providers/models/cliente-filter-response";
import { ClienteListResponse } from "../providers/models/cliente-list-response";
import { GaleriaRequest } from "../providers/models/galeria-request";
import { ClientsReportList } from "../providers/models/clients-report-list";
import { ClientsFilterRequest } from "../providers/models/clients-filter-request";
import { ClienteOsPreventivaResponse } from "../providers/models/cliente-os-preventiva-response";
import { ClienteOsPreventivaPending } from "../providers/models/cliente-os-preventiva-pending";
import { PageResponse } from "../providers/models/page-response";
import { PagedSearchRequest } from "../providers/models/paged-search-request";
import { PagedSearchResult } from "../providers/models/paged-search-result";

@Injectable({
    providedIn: "root",
})
export class ClientService {
    constructor(private httpClient: HttpClientService) {}

    async getAllClientsNames(): Promise<ClienteFilterResponse[]> {
        return this.httpClient.get(`/Cliente/clientes-por-nome`, "");
    }

    async getClientsPreventiveOssNames(): Promise<ClienteFilterResponse[]> {
        return this.httpClient.get(
            `/Cliente/lista-clientes-oss-preventivas`,
            ""
        );
    }

    async getClientsListOsPreventiva(): Promise<ClienteOsPreventivaResponse[]> {
        return this.httpClient.get(`/Cliente/abertura/os/preventiva`, "");
    }

    async getAllClientsList(): Promise<ClienteListResponse[]> {
        return this.httpClient.get(`/Cliente/clientes/list/response`, "");
    }

    async getAllClients(): Promise<Cliente[]> {
        return this.httpClient.get(`/Cliente`, "");
    }
    async getAllClientsReport(
        clientsFilterRequest: ClientsFilterRequest
    ): Promise<ClientsReportList[]> {
        return this.httpClient.get(
            `/Cliente`,
            `/reports-list`,
            clientsFilterRequest
        );
    }

    public getClientsByNameSeller(vendedorNome: string): Promise<Cliente[]> {
        return this.httpClient.get(
            `/Cliente`,
            `/nome/vendedor/${vendedorNome}`
        );
    }
    public getClientsByDates(
        pageDatesRequest: PageDatesRequest
    ): Promise<Cliente[]> {
        return this.httpClient.post(
            `/Cliente`,
            `/clientes/datas`,
            pageDatesRequest
        );
    }

    public getClientByName(clienteNome: string): Promise<Cliente> {
        return this.httpClient.get(`/Cliente`, `/nome/${clienteNome}`);
    }

    public getClientsByStep(etapa: StepEnum): Promise<Cliente[]> {
        return this.httpClient.get(`/Cliente`, `/cliente/por/etapa/${etapa}`);
    }

    public getClientsBySituation(situacao: SituationEnum): Promise<Cliente[]> {
        return this.httpClient.get(`/Cliente`, `/cliente/situacao/${situacao}`);
    }

    public getClientsByNameId(clienteNomeId: string): Promise<Cliente[]> {
        return this.httpClient.get(
            `/Cliente`,
            `/clientes-nome-id/${clienteNomeId}`
        );
    }

    public getClientById(clienteId: number): Promise<Cliente> {
        return this.httpClient.get(`/Cliente`, `/${clienteId}`);
    }

    public getClientPreventiveOsById(
        clienteId: number
    ): Promise<ClienteFilterResponse> {
        return this.httpClient.get(
            `/Cliente`,
            `/cliente-aceite-os-preventiva/${clienteId}`
        );
    }

    public getClientsOssPreventivesPendingPages(
        request: PagedSearchRequest
    ): Promise<PageResponse<PagedSearchResult<ClienteOsPreventivaPending>>> {
        return this.httpClient.get(
            `/Cliente`,
            "/oss-preventivas-pending-pages",
            request
        );
    }

    public getClientsByCity(cidade: string): Promise<Cliente[]> {
        return this.httpClient.get(
            `/Cliente`,
            `/clientes/por/cidade/${cidade}`
        );
    }

    public registerClient(
        cliente: Cliente,
        usuarioLogadoId: number
    ): Promise<Cliente> {
        return this.httpClient.post(`/Cliente`, `/${usuarioLogadoId}`, cliente);
    }

    public registerPasswordClient(
        clienteId: number,
        senha: string
    ): Promise<Cliente> {
        return this.httpClient.post(
            `/Cliente`,
            `/cadastrar/senha/cliente/${clienteId}/${senha}`,
            true
        );
    }

    public updateClient(
        cliente: Cliente,
        usuarioLogadoId: number
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/atualizar-cliente/${cliente.id}/${usuarioLogadoId}`,
            cliente
        );
    }

    public withdrawClientProject(
        usuarioRequest: UsuarioRequest
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/motivo-de-desistencia/projeto/cliente`,
            usuarioRequest
        );
    }

    public putAssociateSystemIndicator(
        clienteId: number,
        usuarioLogadoId: number
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/indicador/sistema/${clienteId}/${usuarioLogadoId}`,
            ""
        );
    }

    public financingReprove(usuarioRequest: UsuarioRequest): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/reprova/financiamento`,
            usuarioRequest
        );
    }

    public deleteClient(
        clienteId: number,
        usuarioLogadoId: number
    ): Promise<any> {
        return this.httpClient.delete(
            `/Cliente`,
            `/id/${clienteId}/${usuarioLogadoId}`
        );
    }

    public saveImagem(
        clienteId: number,
        usuarioLogadoId: number,
        file: File
    ): Promise<Cliente> {
        const fileToUpload = file as File;
        const formData = new FormData();
        formData.append("file", fileToUpload);

        return this.httpClient.post(
            `/Cliente`,
            `/upload-image/${clienteId}/${usuarioLogadoId}`,
            formData
        );
    }

    public verifyEmail(email: string): Promise<Cliente> {
        return this.httpClient.get("/Cliente", `/email/${email}`);
    }

    public verifyCpf(cpf: string): Promise<Boolean> {
        return this.httpClient.get("/Cliente", `/cpf/${cpf}`);
    }

    public resendEmailRegisterClient(usuarioId: number): Promise<boolean> {
        return this.httpClient.post(
            "/Cliente",
            `/reenvio/email/cadastro/${usuarioId}`,
            true
        );
    }

    public notifyClientsPreventively(clienteId: number): Promise<boolean> {
        return this.httpClient.post(
            "/Cliente",
            `/notificar-cliente-os-preventiva/${clienteId}`,
            true
        );
    }

    public notNotifyClientsPreventively(clienteId: number): Promise<boolean> {
        return this.httpClient.post(
            "/Cliente",
            `/nao-notificar-cliente-os-preventiva/${clienteId}`,
            true
        );
    }

    public saveDocument(
        galeriaRequest: GaleriaRequest,
        usuarioLogadoId: number,
        file: File
    ): Promise<any> {
        const fileToUpload = file as File;
        const formData = new FormData();
        formData.append("file", fileToUpload);
        formData.append("galeriaRequest", JSON.stringify(galeriaRequest));

        return this.httpClient.post(
            `/Cliente`,
            `/upload-documento/${usuarioLogadoId}`,
            formData
        );
    }

    public getPdfClient(clienteId: number): Promise<any> {
        return this.httpClient.getFile(
            `/Cliente`,
            `/relatorio-pdf/${clienteId}`,
            { responseType: "blob" }
        );
    }

    public updateSituation(
        clienteId: number,
        userLoggedId: number,
        cliente: Cliente
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/atualizar-situacao/${clienteId}/${userLoggedId}/${cliente.situacao}/${cliente.statusSituacao}`,
            cliente
        );
    }

    public updateStep(
        clienteId: number,
        userLoggedId: number,
        cliente: Cliente
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/atualizar-etapa/${clienteId}/${userLoggedId}/${cliente.etapa}/${cliente.statusEtapa}`,
            cliente
        );
    }

    public updateQuantityOsClient(clienteId: number): Promise<boolean> {
        return this.httpClient.put(
            `/Cliente`,
            `/atualizar/quantidade/os/${clienteId}`,
            true
        );
    }

    public backSituation(
        cliente: Cliente,
        usuarioLoagadoId: number
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/voltar-situacao/${usuarioLoagadoId}`,
            cliente
        );
    }

    public backStep(
        cliente: Cliente,
        usuarioLogadoId: number
    ): Promise<Cliente> {
        return this.httpClient.put(
            `/Cliente`,
            `/voltar-etapa/${usuarioLogadoId}`,
            cliente
        );
    }

    public allListDownloadClients(
        clientsReport: ClienteListResponse[]
    ): Promise<any> {
        return this.httpClient.extractExcelFile(
            `/Cliente`,
            `/clientes-export`,
            clientsReport
        );
    }

    public allListDownloadClientsReport(
        clientsReport: ClientsReportList[]
    ): Promise<any> {
        return this.httpClient.extractExcelFile(
            `/Cliente`,
            `/clientes-export-reports`,
            clientsReport
        );
    }
}
