import { Injectable } from '@angular/core';
import { HttpClientService } from '../providers/services/http-client.service';
import { ReportsDashboardResponse } from '../models/reports/reports-dashboard-response';
import { GraphicsReportsResponse } from '../models/reports/reports-graphics-response';

@Injectable({
  providedIn: "root",
})
export class ReportsService {

  constructor(private httpClient: HttpClientService) {}

  public getReportsDashboard(): Promise<ReportsDashboardResponse> {
    return this.httpClient.get(`/Report`,  '');
  } 

  
  public getReportsGraphics(): Promise<GraphicsReportsResponse> {
    return this.httpClient.get(`/Report`,  '/graphics');
  } 
}