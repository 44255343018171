import { Injectable } from '@angular/core';
import { HttpClientService } from '../providers/services/http-client.service';
import { Endereco } from '../providers/models/endereco';

@Injectable()

export class EnderecoService {

  constructor(private httpClient: HttpClientService) { }
  
  public getEnderecos(): Promise<Endereco[]>{
    return this.httpClient.get('/Endereco', '');
  }

  public getEnderecoPorId(enderecoId: number): Promise<Endereco>{
    return this.httpClient.get('/Endereco', `/endereco/${enderecoId}`);
  }

  public putEndereco(endereco: Endereco): Promise<Endereco> {
    return this.httpClient.put('/Endereco', `/atualizar-endereco/${endereco.id}`, endereco);
  }

  public postEndereco(endereco: Endereco, clienteId: number): Promise<Endereco> {
    return this.httpClient.post('/Endereco', `/cadastro/${clienteId}`, endereco);
  }

  public getEnderecosPorIdCliente(clienteId: number): Promise<Endereco[]>{
    return this.httpClient.get('/Endereco', `/cliente/${clienteId}`);
  }

  public deleteEndereco(enderecoId: number, usuarioLogadoId: number): Promise<any> {
    return this.httpClient.delete('/Endereco', `/id/${enderecoId}/${usuarioLogadoId}`);
  }  
}