import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BadgeModule } from "primeng/badge";
import { CardModule } from "primeng/card";
import { ButtonModule } from "primeng/button";
import { ButtonBackModule } from "src/app/components/button-back/button-back.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PaginatorModule } from "primeng/paginator";
import { ProjectService } from "src/app/services/project.service";
import { ProjectRoutingModule } from "./project-routing.module";
import { ProjectRegisterComponent } from "./register/project-register.component";
import { ProjectSellerListComponent } from "./seller-list/project-seller-list.component";
import { DialogModule } from "primeng/dialog";
import { TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { InputTextareaModule } from "primeng/inputtextarea";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextModule } from "primeng/inputtext";

@NgModule({
    declarations: [ProjectSellerListComponent, ProjectRegisterComponent],
    imports: [
        CommonModule,
        FormsModule,
        CardModule,
        InputTextareaModule,
        InputTextModule,
        TableModule,
        TagModule,
        DialogModule,
        InputMaskModule,
        PaginatorModule,
        ReactiveFormsModule,
        BadgeModule,
        ProjectRoutingModule,
        ButtonBackModule,
        ButtonModule,
    ],
    exports: [ProjectRegisterComponent],
    providers: [ProjectService],
})
export class ProjectModule {}
