import { NgModule } from "@angular/core";
import { SpinnerComponent } from "src/app/components/spinner/spinner.component";
import { SpinnerService } from "src/app/services/spinner.service";

@NgModule({
    declarations: [SpinnerComponent],
    exports: [SpinnerComponent],
    providers: [SpinnerService],
})
export class SpinnerModule {}
