import { Injectable } from "@angular/core";
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class CryptoService {

    private passKey: any;
    private initVector: any;

    constructor() {
        this.passKey = CryptoJS.enc.Utf8.parse('@1B2c3D4e5F6g7H8');
        this.initVector = CryptoJS.enc.Utf8.parse('@1B2c3D4e5F6g7H8');
    }

    encryptMessage(message: any): string {
        if (message == null) return '';

        if (message.constructor == [].constructor || message.constructor == {}.constructor) {
            message = JSON.stringify(message);
        }

        let encryptedMessage = CryptoJS.AES.encrypt(message, this.passKey, {
            iv: this.initVector,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return encryptedMessage.toString();
    }

    decryptMessage(encryptedMessage: any): any {
        if (encryptedMessage == null) return null;

        const decryptedMessage = CryptoJS.AES.decrypt(encryptedMessage, this.passKey, {
            iv: this.initVector,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        const message = decryptedMessage.toString(CryptoJS.enc.Utf8);

        if (message.startsWith("[") || message.startsWith("{")) {
            return JSON.parse(message);
        }
        return message;
    }
}
