import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ProjectRegisterComponent } from "./register/project-register.component";
import { ProjectSellerListComponent } from "./seller-list/project-seller-list.component";

const routes: Routes = [
    {
        path: "vendedor/:id/:nome/:situacao",
        pathMatch: "full",
        component: ProjectSellerListComponent,
    },
    {
        path: "editar/:id",
        pathMatch: "full",
        component: ProjectRegisterComponent,
    },
    {
        path: "cadastro",
        pathMatch: "full",
        component: ProjectRegisterComponent,
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ProjectRoutingModule {}
