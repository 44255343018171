import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'src/app/gerenciamento-estado/store/store';
import { Usuario } from "src/app/providers/models/usuario";
import { StateUsuario } from '../state/states';

const initialState: StateUsuario = { usuarios: [] }

export class StoreUsuario extends Store<Usuario> {
    private subject = new BehaviorSubject<StateUsuario>(initialState);
    private store$ = this.subject.asObservable();
    
    public override get currentValue() {
        return this.subject.value;
    }

    public override setState(name: string, usuario: any): void {     
        this.subject.next({
            ...this.currentValue,
            [name]: usuario
        })
    }

    public override getList(): Observable<Usuario[]> {
        return this.store$.pipe(
            map(store => store.usuarios)
        )
    }

    public override delete(data: Usuario, name: string): void {
        const dataUpdateUserCurrent = this.currentValue.usuarios.map(user=> {
        if (user.id == data.id) {
            return { ...user,  isActive: false};
        }
        return user;
        });

        this.setState(name, dataUpdateUserCurrent)
    }

    public override update(name: string, data: Usuario): void {
        const dataUpdateUserCurrent = this.currentValue.usuarios.map(user=> {
        if (user.id == data.id) {
            return { ...data };
        }
        return user;
        });
        
        this.setState(name, dataUpdateUserCurrent);
    }

}
