import { Injectable } from "@angular/core";
import { Notificacao } from "../providers/models/notificacao";
import { PageDatesRequest } from "../providers/models/pageDatesRequest";
import { HttpClientService } from "../providers/services/http-client.service";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(private httpClient: HttpClientService) {}

    public getNotifications(): Promise<Notificacao[]> {
        return this.httpClient.get("/Notificacao", "");
    }

    public getNotificationsByFilter(
        usuarioNome: string
    ): Promise<Notificacao[]> {
        return this.httpClient.get("/Notificacao", `/filtro/${usuarioNome}`);
    }

    public getNotificationsByUserId(usuarioId: number): Promise<Notificacao> {
        return this.httpClient.get("/Notificacao", `criadas-por/${usuarioId}`);
    }

    public getNotificationsById(notificacaoId: number): Promise<Notificacao> {
        return this.httpClient.get("/Notificacao", `/${notificacaoId}`);
    }

    public getNotificationsByDates(
        pageDatesRequest: PageDatesRequest
    ): Promise<Notificacao[]> {
        return this.httpClient.post(
            "/Notificacao",
            `/notificacoes/datas`,
            pageDatesRequest
        );
    }
}
