export const ConvertValues = {

    maskPhone(phone: string) {
      if(phone){
        phone = phone?.replace(/^0/, "");
        if (phone?.length > 10) {
          phone = phone?.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
        } else if (phone.length > 5) {
          phone = phone?.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
        } else if (phone.length > 2) {
          phone = phone?.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
        } else {
          phone = phone?.replace(/^(\d*)/, "($1");
        }
      }

      return phone;
    },

    maskCpf(cpf: string) {
      return `${cpf.substr(0, 3)}.${cpf.substr(3, 3)}.${cpf.substr(6, 3)}-${cpf.substr(9)}`;
    },

    maskCep(cep: string) {
      return cep?.replace(/(\d{5})(\d)/,'$1-$2');
    },

    maskValueReal(value: string) {
      return 'R$ '+value?.replace(/([0-9]{3})$/g, ".$1") + ',00';
    },

    convertNameCapitalize(name: string): string {
      if (name != undefined) {
        name = name.trimEnd();
        const palavras = name.split(" "); 
        for (let i = 0; i < palavras.length; i++) {
          palavras[i] = palavras[i][0]?.toUpperCase() + palavras[i].substr(1);
        };
        return palavras.join(" ");;
      }
      return name;
    }
  }
  