import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalSendApproveComponent } from './modal-send-approve.component';



@NgModule({
  declarations: [
    ModalSendApproveComponent
  ],
  imports: [
    CommonModule
  ]
})
export class ModalSendApproveModule { }
