import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectClientRegisterComponent } from './register/project-client-register.component';
import { ProjectClientListComponent } from './list/project-client-list.component';

const routes: Routes = [
  {
    path: 'lista',
    pathMatch: "full",
    component: ProjectClientListComponent,
  }, 
  {
      path: "editar/:id",
      pathMatch: "full",
      component: ProjectClientRegisterComponent
  },
  {
      path: "cadastro",
      pathMatch: "full",
      component: ProjectClientRegisterComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectClientRoutingModule { }
