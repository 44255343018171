import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Store } from 'src/app/gerenciamento-estado/store/store';
import { StatePedido } from '../state/states';
import { ProdutoPedido } from 'src/app/providers/models/produto-pedido';

const initialState: StatePedido = { pedidos: [] }

export class StorePedido extends Store<ProdutoPedido> {
    private subject = new BehaviorSubject<StatePedido>(initialState);
    private store$ = this.subject.asObservable();
    
    public override get currentValue() {
        return this.subject.value;
    }

    public override setState(name: string, pedidos: any): void {     
        this.subject.next({
            ...this.currentValue,
            [name]: pedidos
        })
    }

    public override getList(): Observable<ProdutoPedido[]> {
        return this.store$.pipe(
            map(store => store.pedidos)
        )
    }

    public override delete(data: ProdutoPedido, name: string): void {
        const dataUpdatePedidoCurrent = this.currentValue.pedidos.map(pedido=> {
        if (pedido.id == data.id) {
            return { ...pedido,  isActive: false};
        }
        return pedido;
        });

        this.setState(name, dataUpdatePedidoCurrent)
    }

    public override update(name: string, data: ProdutoPedido): void {
        const dataUpdatePedidoCurrent = this.currentValue.pedidos.map(pedido=> {
        if (pedido.id == data.id) {
            return { ...data };
        }
        return pedido;
        });
        
        this.setState(name, dataUpdatePedidoCurrent);
    }

}
