import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "cep",
})
export class CepPipe implements PipeTransform {
    transform(value: string, ...args: any[]): any {
        if (value != undefined) return value.slice(0, 5) + "-" + value.slice(5);
        else return "";
    }
}
